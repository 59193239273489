import { useLocation,Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import MenuStyle from "./MenuBar.module.scss";
import HamburgerMenu from "./HamburgerMenu";
import logo from "../images/assets/logo.png"
import gangjin from "../images/assets/gangjin.png"


export const MenuBar = () => {
	const [isHeaderVisible, setIsHeaderVisible] = useState(true);
	const [lastScrollPos, setLastScrollPos] = useState(0);
	const [currentPath, setCurrentPath] = useState('');
	const [showSubMenu, setShowSubMenu] = useState(false);
	const [screenResponse, setScreenResponse] = useState(true);
	
	useEffect(() => {
		updateWindowResize();
		window.addEventListener('resize', updateWindowResize);
		return () => {
			window.removeEventListener('resize', updateWindowResize);
		}
	}, []);
	
	const updateWindowResize = () => {
		window.innerWidth > 748 ? setScreenResponse(true) : setScreenResponse(false);
	}
	
	const handleMouseEnter = () => {
		setShowSubMenu(true);
	};
	
	const handleMouseLeave = (e) => {
		const { relatedTarget } = e;
		if (
			relatedTarget &&
			(relatedTarget.classList.contains(MenuStyle.menuItem) ||
				relatedTarget.classList.contains(MenuStyle.subMenu))
		) {
			// Keep submenu visible if mouse is still within the bounds of the main menu item or the submenu
			return;
		}
		setShowSubMenu(false);
	};
	
	const location = useLocation();
	let headerTitle;
	// const languageControl = Singleton.getInstance();
	useEffect(() => {
		setCurrentPath(location.pathname);
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	},[location]);
	
	const handleScroll = () => {
		const currentScrollPos = window.pageYOffset;
		if(currentScrollPos == 0)
			setIsHeaderVisible(true)
		else
			setIsHeaderVisible(false)
		setLastScrollPos(currentScrollPos);
	};
	
	//.startsWith 이 아래의 주소를 전부 포함해서 조건.
	const menuPath = currentPath.startsWith('/templefoodsociety') ? 'templefoodsociety' :
		currentPath.startsWith('/experiencecenter') ? 'experiencecenter' :
			currentPath.startsWith('/dobanfood') ? 'dobanfood' : '';
	
	if(menuPath === 'templefoodsociety') {
		return (
			<div className={`${MenuStyle.menuMain} ${!isHeaderVisible ? MenuStyle.hidden : ''}`}>
				<div className={MenuStyle.menuLogo}>
					<Link style={{textDecoration: 'none', color: 'inherit'}} to="/Front">
						<img alt={'Front'} src={gangjin}/>
					</Link>
				</div>
				<div className={MenuStyle.mobileResponse}>
					<Link style={{marginLeft:'10px', marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
					      to="templefoodsociety/companyhistory">법인연혁</Link>
					{/*법인 연혁*/}
					
					<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
					      to="templefoodsociety/regulation">관리운영규정</Link>
					
					<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
					      to="templefoodsociety/chapterintroduction">지회소개</Link>
					{/*5개 지회*/}
					
					{/*<div className={MenuStyle.menu}>*/}
					{/*	<div*/}
					{/*		className={MenuStyle.menuItem}*/}
					{/*		onMouseEnter={handleMouseEnter}*/}
					{/*		onMouseLeave={handleMouseLeave}*/}
					{/*	>*/}
					{/*		<Link style={{textDecoration: 'none', color: 'inherit'}}*/}
					{/*		      to="templefoodsociety/license/teacher">민간인 자격증</Link>*/}
					{/*	</div>*/}
					{/*	{showSubMenu && (*/}
					{/*		<div className={MenuStyle.subMenu}>*/}
					{/*			<div className={MenuStyle.subMenuItem}>*/}
					{/*				<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}*/}
					{/*				      to="templefoodsociety/license/teacher">*/}
					{/*					사찰음식강사*/}
					{/*				</Link>*/}
					{/*			</div>*/}
					{/*			<div className={MenuStyle.subMenuItem}>*/}
					{/*				<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}*/}
					{/*				      to="templefoodsociety/license/master">*/}
					{/*					사찰음식사범*/}
					{/*				</Link>*/}
					{/*			</div>*/}
					{/*		</div>*/}
					{/*	)}*/}
					{/*</div>*/}
				</div>
				<div className={MenuStyle.hamburgerOn}>
					<HamburgerMenu/>
				</div>
			</div>
		)
	}
	else if(menuPath === 'experiencecenter') {
		return (
			<div className={`${MenuStyle.menuMain} ${!isHeaderVisible ? MenuStyle.hidden : ''}`}>
				<div className={MenuStyle.menuLogo}>
					<Link style={{textDecoration: 'none', color: 'inherit'}} to="/Front">
						<img alt={'Front'} src={gangjin}/>
					</Link>
				</div>
				<div className={MenuStyle.mobileResponse}>
					<Link style={{marginLeft:'10px', marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
					      to="experiencecenter/about">기관소개</Link>
					<div className={MenuStyle.menu}>
						<div
							className={MenuStyle.menuItem}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
						>
							<Link style={{textDecoration: 'none', color: 'inherit'}} to="experiencecenter/regularclass">
								프로그램
							</Link>
						</div>
						{showSubMenu && (
							<div className={MenuStyle.subMenu}>
								<div className={MenuStyle.subMenuItem}>
									<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}} to="experiencecenter/education">
										공무원 대상 프로그램
									</Link>
								</div>
								<div className={MenuStyle.subMenuItem}>
									<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}} to="experiencecenter/guide">
										관광객 유치 프로그램
									</Link>
								</div>
								<div className={MenuStyle.subMenuItem}>
									<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}} to="experiencecenter/ExpLocal">
										군 협업 프로그램
									</Link>
								</div>
								<div className={MenuStyle.subMenuItem}>
									<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}} to="experiencecenter/partnership">
										사찰 연계 프로그램
									</Link>
								</div>
								<div className={MenuStyle.subMenuItem}>
									<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}} to="experiencecenter/etc">
										기타 프로그램
									</Link>
								</div>
								<div className={MenuStyle.subMenuItem}>
									<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}} to="experiencecenter/takeoutsales">
										포장판매 활성화 방안
									</Link>
								</div>
								{/*<div className={MenuStyle.subMenuItem}>*/}
								{/*	<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}} to="experiencecenter/regularclass">*/}
								{/*		정규 사찰음식 교육*/}
								{/*	</Link>*/}
								{/*</div>*/}
							</div>
						)}
					</div>
					
					<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
					      to="experiencecenter/referenceroom">자료실</Link>
					
					<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
					      to="experiencecenter/notices">공지사항</Link>
					
					{
						screenResponse ?
						<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
						      to="experiencecenter/faq">문의사항</Link>
						: 	<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
						         to="experiencecenter/faq">FAQ</Link>
					}
				
				</div>
				<div className={MenuStyle.hamburgerOn}>
					<HamburgerMenu/>
				</div>
			</div>
		)
	}
	else if(menuPath === 'dobanfood') {
		return (
			<div className={`${MenuStyle.menuMain} ${!isHeaderVisible ? MenuStyle.hidden : ''}`}>
				<div className={MenuStyle.menuLogo}>
					<Link style={{textDecoration: 'none', color: 'inherit'}} to="/Front">
						<img alt={'Front'} src={gangjin}/>
					</Link>
				</div>
				<div className={MenuStyle.mobileResponse}>
					<Link style={{marginLeft:'10px', marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
					      to="dobanfood/about">사찰음식이란?</Link>
					
					<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
					      to="dobanfood/menu">메뉴</Link>
					
					<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
					      to="dobanfood/contact">찾아오시는 길</Link>
					{
						screenResponse ?
							<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
							      to="dobanfood/reservation">예약하기</Link>
							:
							<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}
							      to="dobanfood/reservation">예약</Link>
							
					}
					
					{/*<Link style={{marginRight: '10px', textDecoration: 'none', color: 'inherit'}}*/}
					{/*      to="dobanfood/faq">문의사항</Link>*/}
				</div>
				<div className={MenuStyle.hamburgerOn}>
					<HamburgerMenu/>
				</div>
			</div>
		)
	}
};
