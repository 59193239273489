import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {HorizontalRule, VerticalInterval} from "../../../components/LayoutCommponents";
import Modal from "../../../components/Modal"
import styles from "./Busan.module.scss";
import TColor from '../../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../../styles/TextStyled";

import photo from "../../../images/assets/society/Busan/photo.png"
import info from "../../../images/assets/society/Busan/busan.png"
import b01 from "../../../images/assets/society/Busan/01.png"
import b02 from "../../../images/assets/society/Busan/02.png"
import b03 from "../../../images/assets/society/Busan/busan01.jpeg"



AOS.init({disable: true});

const Busan = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 최상단 이동
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					부산 지회
				</div>
			</div>
			<div className={styles.backGround}>
				<H1 className={styles.head} bold color={TColor.black}>
					부산 지회
				</H1>
				<img className={styles.photo} src={photo}/>
				<div className={styles.history}>
					<div className={styles.year}>
						성명
					</div>
					<div className={styles.milestones}>
						김경숙
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						학력
					</div>
					<div className={styles.milestones}>
						동국대학교 교육대학원 사찰조리교육전공 석사
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						現 소속
					</div>
					<div className={styles.milestones}>
						소연힐링푸드연구소 대표
						<br/>
						사)홍승스님의 사찰음식연구회 부산지회장
						<br/>
						부산조리사협회 한국전통채식분과장
						<br/>
						<br/>
						<br/>
						부산전통문화체험관 전통문화 체험 프로그램 사찰음식 운영
						<br/>
						경남정보대학교 평생교육원 사찰음식/한식 정규수업
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<H1 className={styles.head} bold color={TColor.black}>
					경력사항
				</H1>
				<div className={styles.history}>
					<div className={styles.year}>
						2019~2022
					</div>
					<div className={styles.milestones}>
						부산기장 농업기술센터 식문화 역량강화 교육
						<br/>
						[우리 쌀 가공식품 활용 전문교육 // 녹차비건레시피 개발 // 사찰음식 // 시의적절 약선음식]
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2020~2022
					</div>
					<div className={styles.milestones}>
						부산한살림 - 이달의 레시피 // 김장 소 교실 // 사찰음식
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2019 ~ 現
					</div>
					<div className={styles.milestones}>
						부산전통문화체험관 - 전통사찰음식 정규판
						<br/>
						경남정보대학교 평생교육원 - (웰빙)사찰음식 정규반 // 퓨전한식 정규반
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2021
					</div>
					<div className={styles.milestones}>
						교사 직무연수 - 맛있는 한끼 채식 밥상 강의
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2022
					</div>
					<div className={styles.milestones}>
						문화도시조성사업 도시미래학교 - 쉬어가 학교 채식 강의
						<br/>
						부산비건페스티벌 참가 - 전시 및 마켓
						<br/>
						제19회 부산국제음식박람회 쿠킹클래스 - 사찰음식 체험 프로그램 운영
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<H1 className={styles.head} bold color={TColor.black}>
					수상경력
				</H1>
				<HorizontalRule width={'60%'} height={4}/>
				<div className={styles.milestones} style={{marginTop:'10px'}}>
					사찰음식 명인 (제23-2호)<br/>
					조리기능교육 명인 (제22-2호)<br/>
					부산광역시장 -표창장<br/>
					한식세계화추진 리더 (제19-5호)<br/>
					부신시교육감 -감사장<br/>
					부산광역시의회의장 -감사장<br/>
					한국산업인력공단장 -표창장<br/>
					한국조리사협회중앙회 -최우수지도자상<br/>
				</div>
			</div>
			<div className={styles.premiumService}>
				<div className={styles.premiumTitle}>
					<h1>지회 사진</h1>
				</div>
				<div className={styles.premiumContents}>
					<HorizontalRule height={3}/>
					<div className={styles.facilityInfo}>
					</div>
					<div className={styles.premiumContentsItem}>
						<img alt={''} style={{maxWidth:'100%'}}
						     src={info}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''}
						     src={b03}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''}
						     src={b01}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''}
						     src={b02}/>
					</div>
				</div>
				<VerticalInterval height={5}/>
			</div>
		</div>
	);
}


export default Busan;
