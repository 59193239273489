import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Notice.module.scss';
import Pagination from 'react-js-pagination';

let notice = '<h2>1. 정규강좌 수강 안내</h2>\n' +
	'초급반 1기생 모집 – 총 12회\n' +
	'강사 – 홍승스님\n' +
	'교육내용 –사찰음식 이론 및 실습\n' +
	'자격 – 신청자격 제한 없음\n' +
	'수강료 600,000원\n' +
	'포함사항 – 교재, 식재료, 앞치마교육내용\n' +
	'사찰음식 이론 및 실습\n' + '\n'+
	'<strong>안내사항</strong>\n' +
	'<h3>* 수업 진행</h3>\n' +
	'- 이론강의 ▶ 실습 시연강의 ▶ 수강생 실습 ▶ 완성음식 평가\n' +
	'- 1회차, 3가지 메뉴 진행(개강일 메뉴 일정표 제공)\n' +
	'- 3인 1조로 실습\n' +
	'- 준비물 : 교재, 앞치마, 필기도구, 완성 음식 담아갈 용기 3개, 행주 3장\n' +
	': 개강일에 교재, 앞치마 지급!\n\n' +
	'* 수료기준 : 결석 3회까지만 수료증 발급\n\n' +
	'<strong>* 수강료 입금계좌</strong>\n' +
	'- 우체국 600064-02-089355 이홍승\n' +
	': 반드시 신청자 이름으로 입금해주세요!\n\n' +
	'* 환불규정\n' +
	'- 학원법 시행령에 의거한, 내부 규정에 따름\n' +
	'- 개강일로부터 3일 전까지 전액 환불\n' +
	'- 개강 2일 전부터는 1회차 수강료 차감한 금액 환불\n' +
	'- 수업 당일 취소는 당일 수업을 수강한 것으로 정함\n\n' +
	'* 개강 3일 전 개강안내문자 발송'

const notices = [
	{ id: 1, title: '수강생 모집 신청', content: notice, createDate: "2023-03-27", },
	{
		id: 2,
		title: '조선일보 : 다시 문연 강진군 ‘마량 놀토수산시장’… 첫날부터 6000명 몰려',
		content: '조선일보',
		createDate: "2023-04-27",
		url: 'https://www.chosun.com/special/special_section/2023/04/27/J4GM6DK3AFHPBPAEDWO5HLPEGY/'
	},
	{ id: 3, title: '공지사항 3', content: '공지사항 내용 3', createDate: "2023-03-27",  },
	{ id: 4, title: '공지사항 4', content: '공지사항 내용 4', createDate: "2023-03-27",  },
	{ id: 5, title: '공지사항 5', content: '공지사항 내용 5', createDate: "2023-03-27",  },
];

const Notice = () => {
	const [activePage, setActivePage] = useState(1);
	const pageSize = 5;
	
	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
	};
	
	const currentNotices = notices.slice(
		(activePage - 1) * pageSize,
		activePage * pageSize
	);
	
	return (
		<div>
			<div className={styles.wallPage}>
					<div className={styles.titleText}>
						공지사항
					</div>
				</div>
			<div className={styles.noticeList}>
				<h2 className={styles.title}>공지사항</h2>
				<div className={styles.listinfo}>
					<div className={styles.infobox}>번호</div>
					<div className={styles.infobox}>제목</div>
					<div className={styles.infobox}>작성일</div>
				</div>
				<ul className={styles.list}>
					{currentNotices.map((notice) => (
						<div key={notice.id} className={styles.item}>
							<div>{notice.id}</div>
							{notice.url ? (
									<a href={notice.url} target="_blank" rel="noreferrer">
										<div className={styles.noticeTitle}>{notice.title}</div>
									</a>
								) :(
								<Link to={{pathname: `notices/${notice.id}`}} state={{state: notice}}>
									<div className={styles.noticeTitle}>{notice.title}</div>
								</Link>
							)}
							<div>{notice.createDate}</div>
						</div>
					))}
				</ul>
				<div className={styles.pagination}>
					<Pagination
						activePage={activePage}
						itemsCountPerPage={pageSize}
						totalItemsCount={notices.length}
						pageRangeDisplayed={5}
						onChange={handlePageChange}
						itemClass="page-item"
						linkClass="page-link"
						activeClass="active"
						activeLinkClass="active-link"
					/>
				</div>
			</div>
		</div>
	);
};

export default Notice;
