import React, { useState, useEffect } from "react";
import styles from "./Modal.module.scss";
import TColor from "../styles/TimoStyle";
import { Body1, Body2, H2 } from "../styles/TextStyled";

const Modal = ({
	               isNotice,
	               modalHeight,
	               modalWidth,
	               modalTitle,
	               displayOn,
	               children
               }) => {
	const [show, setShow] = useState(false);
	const [screenResponse, setScreenResponse] = useState(true);
	useEffect(() => {
		if (isNotice) {
			setShow(displayMov());
		}
	}, [isNotice]);
	
	useEffect(() => {
		const handleResize = () => {
			window.innerWidth > 748 ? setScreenResponse(true) : setScreenResponse(false);
		};
		
		handleResize(); // Set initial state
		window.addEventListener("resize", handleResize); // Update state on resize
		
		return () => {
			window.removeEventListener("resize", handleResize); // Clean up on unmount
		};
	}, []);
	
	const showModal = () => {
		setShow(true);
	};
	
	const hideModal = () => {
		setShow(false);
	};
	
	const closedTodayModal = () => {
		hideModal();
		displayNoneMov();
	};
	
	const displayMov = () => {
		let currentDate = new Date();
		let nextDate = localStorage.getItem("nextOpenDate");
		
		if (!nextDate) return true;
		
		let compareDate = new Date(nextDate);
		return compareDate.getTime() <= currentDate.getTime();
	};
	
	const displayNoneMov = () => {
		let nextOpenDate = new Date();
		nextOpenDate.setDate(nextOpenDate.getDate() + 1);
		localStorage.setItem("nextOpenDate", nextOpenDate);
		console.log("nextOpenDate :: ", nextOpenDate);
		return true;
	};
	
	return (
		<div style={{ zIndex: 3, width: "100%", height: "100%" }}>
			<div
				className={
					show ? `${styles.modal} ${styles["display-block"]}` : `${styles.modal} ${styles["display-none"]}`
				}
				// onClick={() => hideModal()}
			/>
			{show && (
				<div
					className={styles["modal-main"]}
					style={{
						height: screenResponse
							? modalHeight
								? `${modalHeight}%`
								: "auto"
							: "auto",
						width: screenResponse
							? modalWidth
								? `${modalWidth}%`
								: "600px"
							: "80%"
					}}
				>
					<div
						className={styles["modal-header"]}
					>
						<H2 bold color={TColor.black} className={styles["modal-title"]}>
							{modalTitle}
						</H2>
						
						<div className={styles["close-container"]} onClick={() => hideModal()} >
							<div className={styles.close}/>
						</div>
					</div>
					
					<div className={styles["word-sty"]}>{children}</div>
					
					<div
						className={styles["modal-footer"]}
						style={{
							display: displayOn ? "block" : "none"
						}}
					>
						<div
							onClick={() => closedTodayModal()}
							className={styles["footer-content"]}
						>
							<div className={styles["footer-checkbox"]} onClick={() => closedTodayModal()}/>
							<Body2 color={TColor.black} onClick={() => closedTodayModal()}>오늘 하루 그만 보기</Body2>
						</div>
					</div>
				</div>
			)}
			
			{!displayOn && (
				<Body2
					className={styles["modal-button"]}
					type="button"
					onClick={showModal}
				>
					{modalTitle}
				</Body2>
			)}
		</div>
	);
};

export default Modal;
