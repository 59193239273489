import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import Root from './client/Root';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById("root"));

setInterval(() => {
	root.render(
		<React.StrictMode>
			<Root />
		</React.StrictMode>
	);
}, 1000);
//
// createRoot.render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
