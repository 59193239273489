import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {HorizontalRule} from "../../components/LayoutCommponents";
import Modal from "../../components/Modal"
import styles from "./Company_History.module.scss";
import TColor from '../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../styles/TextStyled";
AOS.init({disable: true});

const Company_History = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	
	useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					법인 연혁
				</div>
			</div>
			<div className={styles.backGround}>
				<H1 className={styles.head} bold color={TColor.black}>
					연혁
				</H1>
				<div className={styles.history}>
					<div className={styles.year}>
						2012
					</div>
					<div className={styles.milestones}>
						경주 에밀레전 사찰음식 초청전시
						<br/>
						법인 설립
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2013
					</div>
					<div className={styles.milestones}>
						강진 백련사 차와 사찰음식 초청전시
						<br/>
						한국직업능력개발원에서 민간인 자격증 취득
						<br/>
						사찰음식강사, 사찰 음식사범 전국에 10개의 지회
						<br/>
						서울, 부산, 대구, 경기, 전남, 창원, 김해, 용인, 인천, 진주
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2016
					</div>
					<div className={styles.milestones}>
						백련사 제 1회 사찰음식경연대회 개최
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2017
					</div>
					<div className={styles.milestones}>
						백련사 제 2회 사찰음식경연대회 개최
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
			</div>
		</div>
	);
}


export default Company_History;
