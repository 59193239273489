import React, { useState, useEffect } from 'react';
import './HamburgerMenu.scss';
import { useLocation,Link } from 'react-router-dom';
import {createGlobalStyle} from 'styled-components';
// import {LanguageChange} from './LanguageChange';

const ScrollLock = createGlobalStyle`
    html {
        overflow-x: hidden;
    }
`;
/* App.jsx */
const App = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	
	const handleMenuClick = () => {
		setMenuOpen(!menuOpen);
	};
	
	const styles = {
		container: {
			position: 'absolute', // 메뉴 버튼을 닫기 위함
			top: 18,
			right: 30,
			zIndex: 3, // 포지션 속성 위의 이 값은 해당 엘리먼트의 z축 상의 위치를 나타낸다.
			opacity: 0.9,
			display: 'flex',
			alignItems: 'center',
			
			
		},
		logo: {
			margin: '0 auto',
		},
		body: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%', // 100vw
			height: '100%', // 100vh
			filter: menuOpen ? 'blur(2px)' : null,
			transition: 'filter 0.5s ease',
		},
	};
	
	const menu = [
		{title: '타이틀로', goto: 'Front'},
		{title: '강진사찰음식체험관', goto: 'experiencecenter/about'},
		{title: '전통사찰음식전문점 도반', goto: 'dobanfood/about'},
		{title: '홍승스님의 사찰음식연구회', goto: 'templefoodsociety/companyhistory'},
	];
	
	const menuItems = menu.map((value, index) => {
		return (
			<MenuItem
				key={index}
				delay={`${index * 0.1}s`}
			>
				<Link onClick={() => handleMenuClick()} style={{textDecoration: 'none', color: 'white'}}
				      to={value.goto}>
					{value.title}</Link>
			</MenuItem>)
	});
	
	return (
		<div>
			<div style={styles.container}>
				<MenuButton open={menuOpen} onClick={() => handleMenuClick()} color='white'/>
				{/*로고의 중앙정렬 문제*/}
				{/*<div style={styles.logo}>Timo Logo</div>*/}
			</div>
			<Menu open={menuOpen}>
				{menuItems}
				{/*<div style={{marginLeft: '25px'}}>*/}
				{/*	<LanguageChange/>*/}
				{/*</div>*/}
				<ScrollLock/> {/*메뉴 open 시 스크롤 Y 잠금*/}
			</Menu>
			<div style={styles.body}>
			</div>
		</div>
	)
}

/* MenuItem.jsx*/
const MenuItem = (props) => {
	const [hover, setHover] = useState(false);
	
	const handleHover = () => {
		setHover(!hover);
	};
	
	const styles = {
		container: {
			opacity: 0,
			animation: `1s appear forwards`,
			animationDelay: props.delay,
		},
		menuItem: {
			fontFamily: `'Open Sans', sans-serif`,
			fontSize: '1.2rem',
			padding: '1rem 0',
			margin: '0 5%',
			cursor: 'pointer',
			color: hover ? 'gray' : '#fafafa',
			transition: 'color 0.2s ease-in-out',
			animation: `0.5s slideIn forwards`,
			animationDelay: props.delay,
			
		},
		line: {
			width: '90%',
			height: '1px',
			background: 'gray',
			margin: '0 auto',
			animation: `0.5s shrink forwards`,
			animationDelay: props.delay,
			
		}
	};
	return (
		<div style={styles.container}>
			<div
				style={styles.menuItem}
				onMouseEnter={() => {
					handleHover();
				}}
				onMouseLeave={() => {
					handleHover();
				}}
				onClick={props.onClick}
			>
				{props.children}
			</div>
			<div style={styles.line}/>
		</div>
	)
}

/* Menu.jsx */
const Menu = (props) => {
	const [open, setOpen] = useState(props.open || false);
	
	useEffect(() => {
		setOpen(props.open);
	}, [props.open]);
	
	const styles = {
		container: {
			position: 'absolute',
			top: 0,
			left: 0,
			height: open ? '100vh' : 0,
			width: '100vw',
			display: 'flex',
			flexDirection: 'column',
			background: 'black',
			opacity: 0.95,
			color: '#fafafa',
			transition: 'height 0.3s ease',
			zIndex: 2,
		},
		menuList: {
			paddingTop: '3rem',
			background: 'black',
		}
	};
	
	return (
		<div style={styles.container}>
			{
				open ?
					<div style={styles.menuList}>
						{props.children}
					</div> : null
			}
		</div>
	);
};

/* MenuButton.jsx */
const MenuButton = (props) => {
	const [open, setOpen] = useState(props.open || false);
	const [color, setColor] = useState(props.color || 'black');
	
	const handleClick = () => {
		setOpen(!open);
	}
	
	const styles = {
		container: {
			height: '32px',
			width: '32px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			cursor: 'pointer',
			padding: '5px',
		},
		line: {
			height: '2px',
			width: '17px',
			background: color,
			transition: 'all 0.2s ease',
		},
		lineTop: {
			transform: open ? 'rotate(45deg)' : 'none',
			transformOrigin: 'top left',
			marginBottom: open ? '3px' : '5px',
		},
		lineMiddle: {
			opacity: open ? 0 : 1,
			transform: open ? 'translateX(-16px)' : 'none',
		},
		lineBottom: {
			transform: open ? 'translateX(-1px) rotate(-45deg)' : 'none',
			transformOrigin: 'top left',
			marginTop: '5px',
		},
	};
	
	return (
		<div style={styles.container}
		     onClick={props.onClick ? props.onClick : handleClick}>
			<div style={{ ...styles.line, ...styles.lineTop }} />
			<div style={{ ...styles.line, ...styles.lineMiddle }} />
			<div style={{ ...styles.line, ...styles.lineBottom }} />
		</div>
	);
}

export default App
