import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {HorizontalRule, VerticalInterval} from "../../../components/LayoutCommponents";
import Modal from "../../../components/Modal"
import styles from "./Ulsan.module.scss";
import TColor from '../../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../../styles/TextStyled";

import main from "../../../images/assets/society/Ulsan/03.jpg"

import u01 from "../../../images/assets/society/Ulsan/01.jpg"
import u02 from "../../../images/assets/society/Ulsan/02.jpg"
import u03 from "../../../images/assets/society/Ulsan/04.jpg"
import u04 from "../../../images/assets/society/Ulsan/prize.jpg"
import u05 from "../../../images/assets/society/Ulsan/05.jpg"
import u06 from "../../../images/assets/society/Ulsan/06.jpg"
import u07 from "../../../images/assets/society/Ulsan/07.jpg"
import u08 from "../../../images/assets/society/Ulsan/08.jpg"
import u09 from "../../../images/assets/society/Ulsan/09.jpg"
import u10 from "../../../images/assets/society/Ulsan/10.jpg"
import u11 from "../../../images/assets/society/Ulsan/11.jpg"

AOS.init({disable: true});

const Ulsan = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 최상단 이동
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					울산 지회
				</div>
			</div>
			<div className={styles.backGround}>
				<H1 className={styles.head} bold color={TColor.black}>
					울산 지회
				</H1>
				<img className={styles.photo} src={main}/>
				<div className={styles.history}>
					<div className={styles.year}>
						성명
					</div>
					<div className={styles.milestones}>
						김경서
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2012
					</div>
					<div className={styles.milestones}>
						동국대 평생교육대 전통사차름식2년수료
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2014
					</div>
					<div className={styles.milestones}>
						(사)홍승스님의 사찰음식전문가과장수료
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2020
					</div>
					<div className={styles.milestones}>
						원광디지털대학 한반건강학과 학사과장졸업
						<br/>
						원광디지털대학 차 문화 경영학과 차문화학사졸업
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2022
					</div>
					<div className={styles.milestones}>
						대구한의대학원 푸드케어약선대학원 재학중
						<br/>
						대구한의대학원 조교수재직중
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2014
					</div>
					<div className={styles.milestones}>
						사찰음식전문가 민간자격증취득<br/>
					</div>
				</div>
				<div className={styles.history}>
					<div className={styles.year}>
						2013
					</div>
					<div className={styles.milestones}>
						제10회대한민국향토요리문화대전향토요리대회대상수상
					</div>
				</div>
				<div className={styles.history}>
					<div className={styles.year}>
						2021
					</div>
					<div className={styles.milestones}>
						대한민국마스타세프 사찰명인<br/>
						(사)울산음식문화원 원장취임
					</div>
				</div>
			</div>
			<div className={styles.premiumService}>
				<div className={styles.premiumTitle}>
					<h1>지회 사진</h1>
				</div>
				<div className={styles.premiumContents}>
					<HorizontalRule height={3}/>
					<div className={styles.facilityInfo}>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.facilityInfo}>
					</div>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={u01}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={u02}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={u03}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={u04}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={u05}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={u06}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={u07}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={u08}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={u09}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={u10}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={u11}/>
					</div>
				</div>
				<VerticalInterval height={5}/>
			</div>
		</div>
	);
}


export default Ulsan;
