import React, {useEffect} from 'react';

const { kakao } = window;

const Map = ({title}) => {
	useEffect(() => {
		let markers = [];
		const container = document.getElementById("map"); // 지도를 표시할 div

		const options = {
			center: new window.kakao.maps.LatLng(34.638417,126.772125),
			level: 4, // 지도의 확대 레벨
		};

		const map = new window.kakao.maps.Map(container, options,);

		const markerPosition  = new kakao.maps.LatLng(34.638417,126.772125);
		const infoPosition  = new kakao.maps.LatLng(34.637617,126.772125);
		const marker = new kakao.maps.Marker({
			position: markerPosition,
			title:'test'
		});
		// 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다
		var zoomControl = new kakao.maps.ZoomControl();
		map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT); // 확대 축소 컨트롤러 넣기.
		map.setZoomable(false); // 패드로 줌인아웃 막기
		
		const infowindow = new kakao.maps.InfoWindow({
			map: map, // 인포윈도우가 표시될 지도
			position : infoPosition,
			content : title ? `<div style="padding:5px;">사찰음식전문점 도반</div>` : `<div style="padding:5px;">강진 사찰음식체험관</div>`,
		});
		
		marker.setMap(map); // (마커표시)
		infowindow.setMap(map); // 인포(텍스트)

		// 설명
		// // 버튼 클릭에 따라 지도 이동 기능을 막거나 풀고 싶은 경우에는 map.setDraggable 함수를 사용
		// function setDraggable(draggable) {
		// 	// 마우스 드래그로 지도 이동 가능여부를 설정
		// 	map.setDraggable(draggable);
		// }
		// // 버튼 클릭에 따라 지도 확대, 축소 기능을 막거나 풀고 싶은 경우에는 map.setZoomable 함수를 사용합니다
		// function setZoomable(zoomable) {
		// 	// 마우스 휠로 지도 확대,축소 가능여부를 설정합니다
		// 	map.setZoomable(zoomable);
		// }

		console.log("loading kakaomap");
	}, []);

	return (
		<div style={{width:'100%', height:'100%'}}>
			<div id="map" style={{width:'100%', height:'720px',position:'relative', overflow:'hidden'}}></div>
		</div>
	);
};

export default Map;
