import React, { useState } from 'react';
import styles from './ReferenceRoom.module.scss';
import p01 from "../../images/assets/referenceroom/p01.jpg";
import p02 from "../../images/assets/referenceroom/p02.jpg";
import p03 from "../../images/assets/referenceroom/p03.jpg";
import p04 from "../../images/assets/referenceroom/p04.jpg";
import p05 from "../../images/assets/referenceroom/p05.jpg";
import p06 from "../../images/assets/referenceroom/p06.jpg";
import food01 from '../../images/assets/referenceroom/food01.jpg';
import food02 from '../../images/assets/referenceroom/food02.jpg';
import food03 from '../../images/assets/referenceroom/food03.jpg';
import food04 from '../../images/assets/referenceroom/food04.jpg';
import food05 from '../../images/assets/referenceroom/food05.jpg';
import food06 from '../../images/assets/referenceroom/food06.jpg';
import Pagination from 'react-js-pagination';

const images = [
	{ id: 0, src: p01 },
	{ id: 1, src: p02 },
	{ id: 2, src: p03 },
	{ id: 3, src: p04 },
	{ id: 4, src: p05 },
	{ id: 5, src: p06 },
];

const ReferenceRoom = () => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const imagesPerPage = 4;
	const totalImages = images.length;
	const totalPages = Math.ceil(totalImages / imagesPerPage);
	const indexOfLastImage = currentImageIndex + imagesPerPage;
	const indexOfFirstImage = currentImageIndex;
	
	// 모달창이 보여지고 있는지의 여부를 나타내는 isModalOpen과
	// 현재 선택한 이미지의 인덱스를 저장하는 selectedImageIndex 상태를 추가합니다.
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedImageIndex, setSelectedImageIndex] = useState(null);
	
	const handlePageChange = (pageNumber) => {
		setCurrentImageIndex((pageNumber - 1) * imagesPerPage);
	};
	
	const nextImage = () => {
		setCurrentImageIndex(
			(currentImageIndex + 1) % (totalImages - imagesPerPage + 1)
		);
	};
	
	const prevImage = () => {
		setCurrentImageIndex(
			(currentImageIndex - 1 + totalImages) % (totalImages - imagesPerPage + 1)
		);
	};
	
	return (
		<div>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					자료실
				</div>
			</div>
			<div className={styles.gallery}>
				<div className={styles.images}>
					{images.slice(indexOfFirstImage, indexOfLastImage).map((image) => (
						<img key={image.id} className={styles.image} src={image.src} alt=""
					     onClick={() => {
						     setSelectedImageIndex(image.id);
						     setIsModalOpen(true);
					     }}
						/>
					))}
				</div>
				{isModalOpen && (
					<div className={styles.modal} onClick={() => setIsModalOpen(false)}>
						<div className={styles.modalBackdrop} onClick={() => setIsModalOpen(false)}/>
						<div className={styles.modalContent}>
							<img
								className={styles.modalImage}
								src={images[selectedImageIndex].src}
								alt=""
							/>
						</div>
					</div>
				)}
				<div className={styles.pagination}>
					<Pagination
						activePage={Math.floor(currentImageIndex / imagesPerPage) + 1}
						itemsCountPerPage={imagesPerPage}
						totalItemsCount={totalImages}
						pageRangeDisplayed={4}
						onChange={handlePageChange}
						itemClass="page-item"
						linkClass="page-link"
						activeClass="active"
						activeLinkClass="active-link"
					/>
				</div>
			</div>
		</div>
	);
};

export default ReferenceRoom;
