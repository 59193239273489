import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import ExpAbout from "../pages/experience_center/About"
import ExpFaq from "../pages/experience_center/FAQ"
import ExpRegular_Class from "../pages/experience_center/programs/Regular_Class"
import ExpLocal from "../pages/experience_center/programs/Local"
import ExpGuide from "../pages/experience_center/programs/Guide"
import ExpExperiential_Education from "../pages/experience_center/programs/Experiential_Education"
import ExpPartnership from "../pages/experience_center/programs/Programs_in_partnership"
import ExpTakeout_Sales from "../pages/experience_center/programs/Takeout _Sales"
import ExpEtc from "../pages/experience_center/programs/Etc"
import ExpReferenceRoom from "../pages/experience_center/ReferenceRoom"
import ExpNotice from "../pages/experience_center/Notice"
import NoticeDetail from '../pages/experience_center/NoticeDetail';

import DobanAbout from "../pages/doban_food/Home"
import DobanFAQ from "../pages/doban_food/FAQ"
import DobanMenu from "../pages/doban_food/Menu"
import DobanReservation from "../pages/doban_food/Reservation"
import DobanContact from "../pages/doban_food/Contact"

// import SocietyAbout from "../pages/temple_food_society/Company"
import CompanyHistory from "../pages/temple_food_society/Company_History"
import Regulation from "../pages/temple_food_society/Regulation"
import Chapter_Introduction from "../pages/temple_food_society/Chapter_Introduction"
import Teacher from "../pages/temple_food_society/Teacher";
import Master from "../pages/temple_food_society/Master"
import Seoul from "../pages/temple_food_society/Seoul/Seoul"
import Jeonnam from "../pages/temple_food_society/Jeonnam/Jeonnam"
import Ulsan from "../pages/temple_food_society/Ulsan/Ulsan"
import Pohang from "../pages/temple_food_society/Pohang/Pohang"
import Busan from "../pages/temple_food_society/Busan/Busan"

import Front from "../pages/Front"
import {MenuBar} from "../components/MenuBar";
import {Header} from "../components/Header"
import {Footer} from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop"
import { Helmet } from 'react-helmet';

class App extends Component {
	render() {
		return (
			<div>
				<Helmet>
					<meta property="og:image" content="%PUBLIC_URL%/OGP.jpg"/>
					<meta property="og:image:type" content="image/jpg"/>
					<meta property="og:image:width" content="1613"/>
					<meta property="og:image:height" content="726"/>
					<meta property="og:image" content="https://gangjintemplefood.com/OGP.jpg" />
				</Helmet>
				<ScrollToTop />
				<Header/>
				<MenuBar/>
				<Routes>
					<Route exact path='/' element={<Front />} />
					<Route exact path='/Front' element={<Front />} />
					<Route exact path='/experiencecenter/about' element={<ExpAbout />} />
					<Route exact path='/experiencecenter/faq' element={<ExpFaq />} />
					<Route exact path='/experiencecenter/regularclass' element={<ExpRegular_Class />} />
					<Route exact path='/experiencecenter/explocal' element={<ExpLocal />} />
					<Route exact path='/experiencecenter/guide' element={<ExpGuide />} />
					<Route exact path='/experiencecenter/partnership' element={<ExpPartnership />} />
					<Route exact path='/experiencecenter/education' element={<ExpExperiential_Education />} />
					<Route exact path='/experiencecenter/takeoutsales' element={<ExpTakeout_Sales />} />
					<Route exact path='/experiencecenter/etc' element={<ExpEtc />} />
					<Route exact path='/experiencecenter/referenceroom' element={<ExpReferenceRoom />} />
					<Route exact path='/experiencecenter/notices' element={<ExpNotice />} />
					<Route path="/experiencecenter/notices/notices/:id" element={<NoticeDetail />} />
					
					<Route exact path='/dobanfood/about' element={<DobanAbout />} />
					<Route exact path='/dobanfood/menu' element={<DobanMenu />} />
					<Route exact path='/dobanfood/reservation' element={<DobanReservation />} />
					{/*<Route exact path='/dobanfood/faq' element={<DobanFAQ />} />*/}
					<Route exact path='/dobanfood/contact' element={<DobanContact />} />
					
					{/*<Route exact path='/templefoodsociety/about' element={<SocietyAbout />} />*/}
					<Route exact path='/templefoodsociety/companyhistory' element={<CompanyHistory />} />
					<Route exact path='/templefoodsociety/regulation' element={<Regulation />} />
					<Route exact path='/templefoodsociety/chapterintroduction' element={<Chapter_Introduction />} />
					{/*<Route exact path='/templefoodsociety/license/teacher' element={<Teacher />} />*/}
					{/*<Route exact path='/templefoodsociety/license/master' element={<Master />} />*/}
					<Route exact path='/templefoodsociety/chapterintroduction/seoul' element={<Seoul />} />
					<Route exact path='/templefoodsociety/chapterintroduction/jeonnam' element={<Jeonnam />} />
					<Route exact path='/templefoodsociety/chapterintroduction/ulsan' element={<Ulsan />} />
					<Route exact path='/templefoodsociety/chapterintroduction/pohang' element={<Pohang />} />
					<Route exact path='/templefoodsociety/chapterintroduction/busan' element={<Busan />} />
				</Routes>
				<Footer/>
			</div>
		);
	}
}

export default App;
