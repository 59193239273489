import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {VerticalInterval} from "../../components/LayoutCommponents";
import {Footer} from "../../components/Footer";
import Modal from "../../components/Modal"
import KakaoMapScript from "../../components/KakaoMapScript"
import AboutStyle from "./About.module.scss";

import TColor from '../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../styles/TextStyled";
import mainphoto from "../../images/assets/main.jpeg"
import about01 from "../../images/assets/about/about-01.jpeg"
import about02 from "../../images/assets/about/about-02.jpeg"
import about03 from "../../images/assets/about/about-03.jpeg"
import about04 from "../../images/assets/about/about-04.png"
import book from "../../images/assets/experiencecenter/book.jpeg"
import front from "../../images/assets/experiencecenter/front.jpg"
import plus from "../../images/assets/plus.png"
import logo from "../../images/assets/gangjin.png"
import info from "../../images/assets/experiencecenter/info.jpg"
import goal from "../../images/assets/front/front_main.jpg"
import hunpan from "../../images/assets/experiencecenter/hunpan.jpeg"
AOS.init({disable: true});

const About = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	
	useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	return (
		<div style={{width: '100%', }}>
			<div className={AboutStyle.wallPage}>
				<div className={AboutStyle.wallPageItem}>
					<H1 bold color={TColor.black}>강진사찰음식체험관<br/>
						사찰음식</H1>
					
					<div className={AboutStyle.barIcon}/>
					{
						screenResponse ?
							<Body2>강진사찰음식문화체험관을 방문한 모든 분들을 환영합니다.</Body2>
							:
							<Body2 center>강진사찰음식문화체험관을 방문한 모든 분들을 환영합니다.</Body2>
					}
				</div>
			</div>
			<div className={AboutStyle.Introduce}>
				<div className={AboutStyle.InfoText}>
					{
						screenResponse ?
							<H1 className={AboutStyle.LineBreak} bold color={TColor.black}>
								강진사찰음식체험관 소개</H1>
							:
							<H1 className={AboutStyle.LineBreak} bold color={TColor.black}>
								강진사찰음식체험관 소개</H1>
					}
					<VerticalInterval height={screenResponse ? 4 : 2}/>
					{
						screenResponse ?
							<Body2 className={AboutStyle.intro} bold color={TColor.gray500}>
								전통문화는 한 나라에서 발생하여 오랜 세월에 걸쳐 이어져 내려오는 우리의 고유한 가치라고 할 수 있습니다.
								<br/>
								<br/>
								전통문화를 계승 한다는 것은 우 리의 과거와 현재 미래를 연결하는 수단입니다.
								<br/>
								우리 민족의 정체성을 찾아가고 알리는 길이기도 합니다. 그렇기 때문에 전통문화를 바탕으로 한 컨텐츠 개발은 각 지자체의 숙제이기도 합니다.
								이에 강진군에서는 예로부터 한국 음식의 하나인 사찰음식의 문화적 가치와 전통성을 바탕으로 하는 강진사찰음식체험관을 설립했습니다.
								<br/>
								<br/>
								전형적인 농어촌 지역인 강진군은 예로부터 인심 좋고 살기 좋은 곳으로 이름이 높습니다.
								동백꽃 피는 남쪽 땅 탐진강이 젖줄기처럼 들판을 적시고 흐르기에 기름지고 오곡과 백과가 풍요로운 이 고을을
								더욱 아름답게 가꾸고 돋보여서 많은 이들의 발길을 이끄는 원동력이 되고 있습니다.
								<br/>
								<br/>
								다양한 문화를 지니고 발전시켜가고 있는 강진군에 또 하나의 새로운 문화로써 사찰음식체험관을 통해
								좀 더 발전된 음식문화를 알 릴 수 있는 곳으로 만들어 나아가고자 합니다.
							</Body2>
							:
							<Body1 className={AboutStyle.intro} color={TColor.gray700}>
								전통문화는 한 나라에서 발생하여 오랜 세월에 걸쳐 이어져 내려오는 우리의 고유한 가치라고 할 수 있습니다.
								<br/>
								<br/>
								전통문화를 계승 한다는 것은 우 리의 과거와 현재 미래를 연결하는 수단입니다.
								<br/>
								<br/>
								우리 민족의 정체성을 찾아가고 알리는 길이기도 합니다. 그렇기 때문에 전통문화를 바탕으로 한 컨텐츠 개발은 각 지자체의 숙제이기도 합니다.
								이에 강진군에서는 예로부터 한국 음식의 하나인 사찰음식의 문화적 가치와 전통성을 바탕으로 하는 강진사찰음식체험관을 설립했습니다.
								<br/>
								<br/>
								전형적인 농어촌 지역인 강진군은 예로부터 인심 좋고 살기 좋은 곳으로 이름이 높습니다.
								동백꽃 피는 남쪽 땅 탐진강이 젖줄기처럼 들판을 적시고 흐르기에 기름지고 오곡과 백과가 풍요로운 이 고을을 더욱 아름답게 가꾸고 돋보여서 많은 이들의 발길을 이끄는 원동력이 되고
								있습니다.
								<br/>
								<br/>
								다양한 문화를 지니고 발전시켜가고 있는 강진군에 또 하나의 새로운 문화로써 사찰음식체험관을 통해
								좀 더 발전된 음식문화를 알 릴 수 있는 곳으로 만들어 나아가고자 합니다.
								<br/>
							</Body1>
					}
				</div>
				<div className={AboutStyle.TechnologyInfoImg}>
					<img style={{width: '100%'}} alt={''} src={hunpan}/>
				</div>
			</div>
			
			<div className={AboutStyle.ComMainSection}>
				<div className={AboutStyle.CompanyItemTitle}>
					<H1 bold color={TColor.white}>강진 사찰음식체험관의 목표</H1>
					<br/>
					<img style={{width: '100%', marginTop:'10px', marginBottom:'10px'}} alt={''} src={goal}/>
					<Body2 color={TColor.gray300}>
						지방자치 시대에서는 각 지역의 다양한 역사, 문화, 그리고 환경 등에 따라 특색 있는 정책을 수립하여 지역의 경쟁력을 높여나가는 한편, 지역이 갖고 있는 다양한 여건을 활용한 아이디어를 가미하여 지역 경제력을 높이고 소득을 증대시키는 정책이 필요합니다.
						<br/>
						<br/>
						강진군이 남도 방문 우선 순위로 꼽히는 이유가 먹거리 문화에 있다고 본다면, 문화적인 측면에서의 다양성을 창출할 필요가 있는 시점입니다.
						<br/>
						<br/>
						사찰음식 체험관의 유치는 강진이 가지고 있는 다양한 먹거리와 청정지역이라는 환경적인 이미지에 도움이 될 뿐만 아니라, 현재 강진군의 정책인 군민들의 일자리 창출, 지역 농산물과 특산물을 이용한 음식을 보급, 발전시키는 것으로써 지역 상권의 활성화 및 소득 증대에 큰 도움이 될 것입니다.
						<br/>
						<br/>
						강진만의 특색을 가진 다양한 프로그램을 통해 국내 뿐만 아니라 국외에도 강진을 알릴 수 있는 계기가 될 수 있을 것입니다.
					</Body2>
				</div>
				{/*<div className={AboutStyle.CompanyItemBox}>*/}
				{/*	<div className={AboutStyle.CompanyItemFirst}>*/}
				{/*		<div className={AboutStyle.CompanyItemContent}>*/}
				{/*			<span>*/}
				{/*				<h2>문화공간</h2>*/}
				{/*				<br/>*/}
				{/*			</span>*/}
				{/*			<VerticalInterval height={2}/>*/}
				{/*			<Body2 className={AboutStyle.textColor}>*/}
				{/*				계절별 사찰음식 레시피 홍보물 제공<br/>*/}
				{/*				사찰음식 전문서적 구비<br/>*/}
				{/*				작은 행사나 소모임을 하는 커뮤니티공간*/}
				{/*			</Body2>*/}
				{/*		</div>*/}
				{/*		<div className={AboutStyle.CompanyItemContentImg} style={{alignItems: 'center'}}>*/}
				{/*			<img className={AboutStyle.imgStyle} alt={''} src={about01}/>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*	*/}
				{/*	<div className={AboutStyle.CompanyItem}>*/}
				{/*		<div className={AboutStyle.CompanyItemContent}>*/}
        {/*      <span>*/}
        {/*        <h2>체험공간</h2>*/}
	      {/*        <br/>*/}
        {/*      </span>*/}
				{/*			<VerticalInterval height={2}/>*/}
				{/*			<Body2 className={AboutStyle.textColor}>*/}
				{/*				월별 사찰음식 강좌(4주)<br/>*/}
				{/*				1일체험(사찰음식 만들기, 발우공양체험)<br/>*/}
				{/*				상시체험(전통다식 만들기, 스님과의 차담)*/}
				{/*			</Body2>*/}
				{/*		</div>*/}
				{/*		<div className={AboutStyle.CompanyItemContentImg} style={{alignItems: 'center'}}>*/}
				{/*			<img className={AboutStyle.imgStyle} alt={''} src={about02}/>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*	<div className={AboutStyle.CompanyItem}>*/}
				{/*		<div className={AboutStyle.CompanyItemContent}>*/}
        {/*      <span>*/}
        {/*        <h2>전시 공간</h2>*/}
        {/*      </span>*/}
				{/*			<VerticalInterval height={2}/>*/}
				{/*			<Body2 className={AboutStyle.textColor}>*/}
				{/*				한국전통사찰 공양간 재현<br/>*/}
				{/*				전통발우 소개<br/>*/}
				{/*				사찰음식 관련 전시*/}
				{/*			</Body2>*/}
				{/*		</div>*/}
				{/*		<div className={AboutStyle.CompanyItemContentImg} style={{alignItems: 'center'}}>*/}
				{/*			<img className={AboutStyle.imgStyle} alt={''} src={about03}/>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*	<div className={AboutStyle.CompanyItem}>*/}
				{/*		<div className={AboutStyle.CompanyItemContent}>*/}
        {/*      <span>*/}
        {/*        <h2>휴식공간</h2>*/}
        {/*      </span>*/}
				{/*			<VerticalInterval height={2}/>*/}
				{/*			<Body2 className={AboutStyle.textColor}>*/}
				{/*				와이파이<br/>*/}
				{/*				휴대폰 충전<br/>*/}
				{/*				도서열람<br/>*/}
				{/*				오늘의 차 시음*/}
				{/*			</Body2>*/}
				{/*		</div>*/}
				{/*		<div className={AboutStyle.CompanyItemContentImg} style={{alignItems: 'center'}}>*/}
				{/*			<img className={AboutStyle.imgStyle} alt={''} src={about04}/>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
			
			<div className={AboutStyle.CoreInfo}>
				<div className={AboutStyle.LogoStyle}>
					<img style={{height: '200px'}} alt={''} src={logo}/>
				</div>
				<div className={AboutStyle.CoreItems}>
					<div id={'first-block'} className={AboutStyle.CoreBlock}>
						<div className={AboutStyle.CoreItemContent}>
							<div className={AboutStyle.CoreItemTitle}>
								배우고
							</div>
							<div className={AboutStyle.CoreItemText}>
								음식으로 배우는<br/>행복과 건강
							</div>
						</div>
						<img className={AboutStyle.plusStyle} alt={''} src={plus}/>
						
						<div className={AboutStyle.CoreItemContent}>
							<div className={AboutStyle.CoreItemTitle}>
								즐기고
							</div>
							<div className={AboutStyle.CoreItemText}>
								쳔연 식재료를 이용한<br/>건강한 채식
							</div>
						</div>
					</div>
					
					
					<img className={AboutStyle.byePlus} alt={''} src={plus}/>
					
					<div id={'second-block'} className={AboutStyle.CoreBlock}>
						<div className={AboutStyle.CoreItemContent}>
							<div className={AboutStyle.CoreItemTitle}>
								보고
							</div>
							<div className={AboutStyle.CoreItemText}>
								고추장 된장<br/>발효 과정
							</div>
						</div>
						
						<img className={AboutStyle.plusStyle} alt={''} src={plus}/>
						<div className={AboutStyle.CoreItemContent}>
							<div className={AboutStyle.CoreItemTitle}>
								듣고
							</div>
							<div className={AboutStyle.CoreItemText}>
								사찰음식<br/>강연
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={AboutStyle.backGround}>
				<H1 className={AboutStyle.LineBreak} bold color={TColor.black}>
					찾아 오시는 길</H1>
				<Body2 style={{marginBottom:'30px'}}>INFOMATION</Body2>
				<div className={AboutStyle.serviceUseGuide}>
					<div className={AboutStyle.hoursOfUse}>
						<div style={{display: 'flex', flexFlow: 'row nowrap'}}>
							<img alt={''}
							     src={'images/time.png'}/>
							<h2 color={TColor.white}>고객센터</h2>
						</div>
						<div style={{marginLeft: '40px'}}>
							<p color={TColor.white}>T. 061-432-6665</p>
							{/*<p color={TColor.white}> M. QnA 게시판 이용바랍니다.</p>*/}
						</div>
						<div style={{display: 'flex', flexFlow: 'row nowrap', paddingTop: '1rem'}}>
							<img alt={''}
							     src={'images/flag.png'}/>
							<h2 color={TColor.white}>오시는 길</h2>
						</div>
						<div style={{marginLeft: '40px'}}>
							<p color={TColor.white}>
								<Body1 bold color={TColor.white}>주소</Body1>
								<br/>
								<Body2 bold color={TColor.white}>전남 강진군 강진오감통 오감길 2 3동 <br/><br/>강진 사찰음식체험관</Body2>
							</p>
						</div>
						<VerticalInterval height={1}/>
						<div style={{marginLeft: '40px', color: TColor.white}}>
							<p>
								{/*<Body2 bold color={TColor.white}>주차안내</Body2>*/}
								{/*<br/>*/}
								{/*- 안국빌딩 내 기계식 주차 이용(체험관 수업 시 1시간 무료, 주차권 도장 지참, 이후 10분에 1,500원)<br/>*/}
								{/*- 체험관 수강생 전용으로 단순 방문객은 주차가 불가합니다.<br/>*/}
								{/*- SUV차량은 종로경찰서 옆 공영주차장을 이용해주세요.<br/>*/}
								{/*- 주말에는 종로 및 광화문 일대 차량통행이 제한되는 곳이 많으니 대중교통 이용을 권장합니다<br/>*/}
							</p>
						</div>
					</div>
					<div className={AboutStyle.googleMap}>
						<KakaoMapScript/>
					</div>
				</div>
			</div>
			
			{/*이벤트 팝업창 추후 워드프레스에서 이미지 받아오게 수정*/}
			{
				<Modal isNotice={true} displayOn={true} modalTitle='매일매일 채식밥상 출간'>
					<a target="_blank" href={"https://search.shopping.naver.com/book/catalog/38225811629?cat_id=50005816&frm=PBOKPRO&query=%ED%99%8D%EC%8A%B9%EC%8A%A4%EB%8B%98+%EC%B1%85&NaPm=ct%3Dlfo0xux4%7Cci%3D5f176e77cd07f12c5159dba69d8caef9413276b0%7Ctr%3Dboknx%7Csn%3D95694%7Chk%3D846fdb5e44c4ced81be75cf492530aeca6454d68"}>
					<div style={{
						width: '100%',
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
						<img alt={""} style={{width: '100%', height: '100%', objectFit: 'contain'}} src={book}/>
					</div>
					</a>
				</Modal>
			}
		</div>
	);
}


export default About;
