import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import FrontStyle from "./Front.module.scss";
import {Footer} from "../components/Footer"
import {VerticalInterval,HorizontalRule} from "../components/LayoutCommponents";
import {Body1, Body2, H1, H2} from "../styles/TextStyled";
import Modal from "../components/Modal"

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SchoolIcon from '@mui/icons-material/School';
import StoreIcon from '@mui/icons-material/Store';

import notice01 from "../images/assets/notices/notice01.png"
const Front = () => {
	useEffect(() => {
	}, []);
	
	return (
		<>
			<div className={FrontStyle.main}>
				<div className={FrontStyle.title}>
					<div className={FrontStyle.t01}>강진 사찰음식 체험관</div>
					{/*<div className={FrontStyle.t02}>도반</div>*/}
					<div className={FrontStyle.t03}>Gangjin Temple Food Experience Center</div>
				</div>
				{/*<div className={FrontStyle.videoWrapper}>*/}
				{/*	<iframe width="560" height="349" src="https://www.youtube.com/embed/tPr94Dr7mME"*/}
				{/*	        title="YouTube video"*/}
				{/*	        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
				{/*	        frameBorder="0"*/}
				{/*	        allowFullScreen></iframe>*/}
				{/*</div>*/}
			<div className={FrontStyle.container}>
					<Link style={{textDecoration: 'none', color: 'inherit'}} to="/experiencecenter/about">
						<div className={FrontStyle.r01}>
							<AccountBalanceIcon style={{width:100, height:100}}/>
							<H2 color={'white'}>강진사찰음식체험관</H2>
						</div>
					</Link>
					<Link style={{textDecoration: 'none', color: 'inherit'}} to="/dobanfood/about">
						<div className={FrontStyle.r02}>
							<StoreIcon style={{width:100, height:100}}/>
							<H2 color={'white'}>전통사찰음식점 도반</H2>
						</div>
					</Link>
					<Link style={{textDecoration: 'none', color: 'inherit'}} to="/templefoodsociety/companyhistory">
						<div className={FrontStyle.r03}>
							<SchoolIcon style={{width:100, height:100}}/>
							<H2 color={'white'}>(사)홍승스님의<br/>사찰음식연구회</H2>
						</div>
					</Link>
				</div>
			</div>
			{
				<Modal isNotice={true} displayOn={true} modalTitle='1. 정규강좌 수강 안내'>
					<a target="_blank" href={"/experiencecenter/notices"}>
						<div style={{
							width: '100%',
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
							<img alt={""} style={{width: '100%', height: '100%', objectFit: 'contain'}} src={notice01}/>
						</div>
					</a>
					{'클릭시 신청페이지로 연결됩니다.'}
				</Modal>
			}
		</>
	);
};

export default Front;
