
export default {

    primary: '#D5FF3D',
    primaryLight: '#EAFF9E',

    secondary: '#1777F2',
    secondaryLight: '#ECF3FF',

    error: '#FF4433',

    white: '#FFFFFF',
    black: '#000000',

    gray100: '#F7F7F7',
    gray300: '#D8D8D8',
    gray500: '#A2A2A2',
    gray700: '#616161',
    gray900: '#333333',
};