import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {VerticalInterval} from "../../components/LayoutCommponents";
import styles from "./Menu.module.scss";

import lunch from "../../images/assets/menu/lunch.jpeg"
import Amenu from "../../images/assets/menu/A_course.jpeg"
import Bmenu from "../../images/assets/menu/B_course.jpeg"
import Cmenu from "../../images/assets/menu/C_course.png"

AOS.init({disable: true});

const Menu = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	const [activeItem, setActiveItem] = useState(1);
	
	useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	
	const menuItems = [
		{
			id: 1,
			name: '점심메뉴',
			description: (
				<>
					<div className={styles.menuItemDescription}>
						<div className={styles.menuItemDescriptionText}>
							<h2>점심 식사 10,000원</h2>
							<h2>점심 특선 (식사 + 요리2품) 16,000원</h2>
							<p>
								점심 식사 + 오늘의 요리 2품
							</p>
							<p>
								(*오늘의 요리는 수시로 변경됩니다)
							</p>
						</div>
							<img
								className={styles.menuItemDescriptionImage}
								src={lunch}
								alt="점심"
							/>
					</div>
				</>
			),
		},
		{
			id: 2,
			name: 'A 코스',
			description: (
				<>
					<div className={styles.menuItemDescription}>
						<div className={styles.menuItemDescriptionText}>
							<div style={{display:'flex',flexFlow:'row nowrap'}}>
								<h2 style={{color:'rgb(68,88,61)'}}>코스1_</h2><h2>정진 精進 ・・・ 3만원</h2>
							</div>
							<p>
								몸을 깨끗이 하고 마음을 가다듬다.
							</p>
							<h4>요리 5품 + 식사 + 후식</h4>
						</div>
						<img
							className={styles.menuItemDescriptionImage}
							src={Amenu}
							alt="점심"
						/>
					</div>
				</>
			),
		},
		{
			id: 3,
			name: 'B 코스',
			description: (
				<>
					<div className={styles.menuItemDescription}>
						<div className={styles.menuItemDescriptionText}>
							<div style={{display:'flex',flexFlow:'row nowrap'}}>
								<h2 style={{color:'rgb(68,88,61)'}}>코스2_</h2><h2>선정 禪定 ・・・ 5만원</h2>
							</div>
							<p>
								반야(般若)의 지혜를 얻고<br/>
								성불하기 위하여 마음을 닦는 수행.<br/>
							</p>
							<h4>요리 7품+식사+후식</h4>
						</div>
						<img
							className={styles.menuItemDescriptionImage}
							src={Bmenu}
							alt="점심"
						/>
					</div>
				</>
			),
		},
		{
			id: 4,
			name: 'C 코스',
			description: (
				<>
					<div className={styles.menuItemDescription}>
						<div className={styles.menuItemDescriptionText}>
							<div style={{display:'flex',flexFlow:'row nowrap'}}>
							<h2 style={{color:'rgb(68,88,61)'}}>코스3_</h2><h2>반야 般若 ・・・ 7만원</h2>
							</div>
							<p>
								진실한 생명을 깨달았을 때<br/>
								나타나는 근원적인 지혜
							</p>
							<h4>전채 + 요리 9품 + 식사 + 후식</h4>
						</div>
						<img
							className={styles.menuItemDescriptionImage}
							src={Cmenu}
							alt="점심"
						/>
					</div>
				</>
			),
		},
	]
	const handleClick = (itemId) => {
		setActiveItem(itemId);
	};
	
	
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					메뉴소개
				</div>
			</div>
			<div className={styles.container}>
				<div className={styles.menuBar}>
					<h1 className={styles.title}>
						메뉴 안내
					</h1>
					<div className={styles.list}>
						<div className={`${styles.menubutton} ${activeItem === 1 ? styles.active : ''}`}
						     onClick={() => handleClick(1)}>점심 메뉴</div>
						<div className={`${styles.menubutton} ${activeItem === 2 ? styles.active : ''}`} 	onClick={() => handleClick(2)}>코스1_ 정진 精進</div>
						<div className={`${styles.menubutton} ${activeItem === 3 ? styles.active : ''}`}	onClick={() => handleClick(3)}>코스2_ 선정 禪定</div>
						<div className={`${styles.menubutton} ${activeItem === 4 ? styles.active : ''}`}	onClick={() => handleClick(4)}>코스3_ 반야 般若</div>
					</div>
				</div>
				<div className={styles.content}>
					<div className={styles.InfoText}>
						<ul className={styles.menuList}>
							{menuItems.map((item) => (
								<li key={item.id} className={styles.menuItem}>
									{activeItem === item.id && (
										<div className={styles.menuItemDescription}>
											{item.description}
										</div>
									)}
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className={styles.menuinfo}>
					<div>
						<h3>제철에 맞는 재료로</h3>
						<h3>요리하여 선보입니다.</h3>
					</div>
					<div className={styles.menubox}>
						<div className={styles.menus}>
							<div className={styles.menu_name}>・ 현미땅콩죽</div>
							현미<text> brown rice </text>, 땅콩 <text>peanut</text>
							
							<div className={styles.menu_name}>・ 현미흑임자죽</div>
							현미<text> brown rice</text>, 깨 <text>black sesame</text>
							
							<div className={styles.menu_name}>・ 토마토 물김치</div>
							토마토<text> totato</text>, 무 <text>radish</text>, 오이 <text>cucumber</text>
							
							<div className={styles.menu_name}>・ 딸기샐러드</div>
							딸기 <text>strawberry</text>, 야채샐러드 <text>vegetable salad</text>
							
							<div className={styles.menu_name}>・ 더덕 잣소스샐러드</div>
							더덕 <text>lance asiabell</text>, 잣 <text>pine nuts</text>, 어린잎 <text>baby leaf</text>
							
							<div className={styles.menu_name}>・ 가지새싹전</div>
							가지<text>eggplant</text>, 새싹 <text>sprout</text>
							
							
							<div className={styles.menu_name}>・ 마튀김</div>
							마 <text>chineseyam</text>
							
							<div className={styles.menu_name}>・ 수수감자 크로켓</div>
							수수 <text>sorghum</text>, 감자 <text>potato</text>, 마 <text>chinese yam</text>
							
							<div className={styles.menu_name}>・ 두릅튀김</div>
							두릅 <text>fatsia Sprout</text>
							
							<div className={styles.menu_name}>・ 호박채전</div>
							호박 <text>pumpkin</text>
						</div>
						{/*요리*/}
						<div className={styles.menus}>
							<div className={styles.menu_name}>・ 수삼냉채</div>
							삼<text>ginseng</text>, 대추 <text>jujube</text>, 배<text>pear</text>,오이<text>cucumber</text>,샐러<text>ccelery</text>
							
							<div className={styles.menu_name}>・ 콩나물잡채</div>
							콩나물<text>bean sprouts</text>, 당면 <text>glass noodles</text>
							
							<div className={styles.menu_name}>・ 취나물잡채</div>
							취나물<text>baby chwee</text>, 당면<text>glass noodles,</text>,파프리카<text>paprika</text>
							
							<div className={styles.menu_name}>・ 표고탕수</div>
							표고버섯 <text>shiitake mushroom</text>, 파프리카 <text>paprika</text>
							
							<div className={styles.menu_name}>・ 구절판</div>
							파프리카 <text>paprika-yellow,red</text>, 더덕 <text>lance asiabell</text>,
							<br/>
							당근 <text>carrot</text>, 오이 <text>cucumber</text>,
							<br/>
							표고버섯 <text>shiitake mushroom</text>, 목이버섯 <text>wood ear mushroom,</text>
							<br/>
							호박 <text>pumpkin</text>, 밀전병 <text>flour pancake</text>
							
							<div className={styles.menu_name}>・ 무전</div>
							무 <text>radish</text>
							
							<div className={styles.menu_name}>・ 생땅콩찹살구이</div>
							땅콩 <text>peanut</text>, 찹쌀 <text>glutinous rice</text>
							
							<div className={styles.menu_name}>・ 황금팽이두부선</div>
							황금팽이 <text>Flammulina velutipes</text> 두부<text>bean curd</text>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


export default Menu;
