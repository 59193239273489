import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {HorizontalRule, VerticalInterval} from "../../../components/LayoutCommponents";
import Modal from "../../../components/Modal"
import styles from "./Pohang.module.scss";
import TColor from '../../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../../styles/TextStyled";

import photo from "../../../images/assets/society/Pohang/photo.jpg"
import profile from "../../../images/assets/society/Pohang/profile.png"

AOS.init({disable: true});

const Pohang = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 최상단 이동
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					포항 지회
				</div>
			</div>
			<div className={styles.backGround}>
				<H1 className={styles.head} bold color={TColor.black}>
					포항 지회
				</H1>
				<img className={styles.photo} src={photo}/>
				<div className={styles.history}>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<img className={styles.photo} src={profile}/>
			</div>
		</div>
	);
}


export default Pohang;
