import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {HorizontalRule, VerticalInterval} from "../../../components/LayoutCommponents";
import Modal from "../../../components/Modal"
import styles from "./Jeonnam.module.scss";
import TColor from '../../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../../styles/TextStyled";

import photo from "../../../images/assets/society/Jeonnam/photo.jpeg"
import j01 from "../../../images/assets/society/Jeonnam/01.jpg"
import j02 from "../../../images/assets/society/Jeonnam/02.png"
import j03 from "../../../images/assets/society/Jeonnam/03.jpg"

AOS.init({disable: true});

const Jeonnam = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 최상단 이동
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					전남 지회
				</div>
			</div>
			<div className={styles.backGround}>
				<H1 className={styles.head} bold color={TColor.black}>
					전남 지회
				</H1>
				<img className={styles.photo} src={photo}/>
				<div className={styles.history}>
					<div className={styles.year}>
						성명
					</div>
					<div className={styles.milestones}>
						윤문희
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						역임
					</div>
					<div className={styles.milestones}>
						윤문희자연요리문화원장
						<br/>
						홍승스님의사찰음식연구회 전남지부지회장
						<br/>
						대한민국사찰음식장인
						<br/>
						사찰음식전문가,사범
						<br/>
						김치소믈리에
						<br/>
						아동요리지도사
						<br/>
						푸드코디네이터
						<br/>
						메뉴개발상담사
						<br/>
						시제음식지도사
						<br/>
						펫푸드지도사
						<br/>
						꽃차지도사
						<br/>
						식이요법지도사
						<br/>
						<br/>
						해남 먹거리위원회 건강먹거리 소위원장
						<br/>
						해남 먹거리축제 자문위원
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<H1 className={styles.head} bold color={TColor.black}>
					수상 이력
				</H1>
				<div className={styles.history}>
					<div className={styles.year}>
						2017
					</div>
					<div className={styles.milestones}>
						만덕산 백련사 차와음식요리경연대회심사위원
						<br/>
						만덕산 백련사 차와음식요리 경연대회 조직위원상
						<br/>
						서울국제 푸드그랑프리 사찰음식 개인 대상
						<br/>
						서울국제 푸드그랑프리 사찰음식 단체 국무청리상
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2018
					</div>
					<div className={styles.milestones}>
						목포대평생교육원 전통음식과 폐백과정수료
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2019
					</div>
					<div className={styles.milestones}>
						대한민국모범인 사찰음식대상
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						2020년
					</div>
					<div className={styles.milestones}>
						사찰음식과자연요리 연구개발 나눔과봉사 국회의원표창
					</div>
				</div>
			</div>
			<div className={styles.premiumService}>
				<div className={styles.premiumTitle}>
					<h1>지회 사진</h1>
				</div>
				<div className={styles.premiumContents}>
					<HorizontalRule height={3}/>
					<div className={styles.facilityInfo}>
					</div>
					<div className={styles.premiumContentsItem}>
						<img alt={''}
						     src={j01}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.facilityInfo}>
					</div>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={j02}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={j03}/>
					</div>
					<HorizontalRule height={3}/>
				</div>
				<VerticalInterval height={5}/>
			</div>
		</div>
	);
}


export default Jeonnam;
