import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {VerticalInterval} from "../../components/LayoutCommponents";
import Modal from "../../components/Modal"
import styles from "./Reservation.module.scss";
import TColor from '../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../styles/TextStyled";

import PhoneIcon from '@mui/icons-material/Phone';
import naver from "../../images/assets/doban/naver_reservation.png"
import go from "../../images/assets/doban/gonaver.png"
import reserve from "../../images/assets/doban/reserve.png"
AOS.init({disable: true});

// 예약하기 수정.
const Reservation = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	
	useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	const phoneNumber = '061-432-6665'; // 전화번호
	
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					예약안내
				</div>
			</div>
			<div className={styles.reserve_row}>
				<div className={styles.reserve_main}>
					<H1 className={styles.LineBreak} bold color={TColor.black}>
						사찰음식전문점 도반
					</H1>
					<H1 className={styles.LineBreak} bold color={TColor.black}>
						전화 문의
					</H1>
					<VerticalInterval height={screenResponse ? 4 : 2}/>
					<Body2 className={styles.LineBreak} bold color={TColor.gray500}>
						*버튼을 누르면 전화 통화로 연결 됩니다.
					</Body2>
					<div className={styles.round}>
						<a href={`tel:${phoneNumber}`} className={styles.call}>
						<PhoneIcon style={{width:100, height:100}}/>
							<h1 className={styles.LineBreak} bold color={TColor.white}>
								061-432-6665
							</h1>
						</a>
					</div>
				</div>
				<div className={styles.reserve_main}>
					<H1 className={styles.LineBreak} bold color={TColor.black}>
						사찰음식전문점 도반
					</H1>
					<H1 className={styles.LineBreak} bold color={TColor.black}>
						네이버 예약
					</H1>
					<VerticalInterval height={screenResponse ? 4 : 2}/>
					<Body2 className={styles.LineBreak} bold color={TColor.gray500} style={{textAlign:'center'}}>
						사찰음식점 도반은 네이버 예약을 통해 사전에 예약을 할 수 있습니다.
						<br/>
						아래의 이미지를 누르면 네이버 지도의 예약으로 이동됩니다.
					</Body2>
					<a href={'https://map.naver.com/v5/entry/place/1636055880?placePath=%2Fhome&c=15,0,0,0,dh'}>
						<img src={go} style={{ backgroundColor:'white'}} alt={'naver예약'}/>
					</a>
					<a href={'https://map.naver.com/v5/entry/place/1636055880?placePath=%2Fhome&c=15,0,0,0,dh'}>
						<img src={reserve} style={{ backgroundColor:'white'}} alt={'naver예약'}/>
					</a>
				</div>
			</div>
		</div>
	);
}


export default Reservation;
