import React from 'react';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './NoticeDetail.module.scss';



const NoticeDetail = () => {
	const location = useLocation();
	const { state } = location.state;
	const navigate = useNavigate();
	
	const { id } = useParams();
	// const notice = notices.find((notice) => notice.id === parseInt(id));

	
	return (
		<div className={styles.noticeDetail}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					공지사항
				</div>
			</div>
			<div className={styles.content}>
				<div className={styles.content_head}>
					<div className={styles.title}>
						{state.title}
					</div>
					<div className={styles.title}>
						관리자 : {state.createDate}
					</div>
				</div>
				<div className={styles.board}>
					<div className={styles.body} dangerouslySetInnerHTML={{ __html: state.content }}>
						{/*{state.content}*/}
					</div>
				</div>
				<div className={styles.goback} onClick={ () => navigate(-1)}>
					목록으로
				</div>
			</div>
		</div>
	);
};

export default NoticeDetail;
