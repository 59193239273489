import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {VerticalInterval} from "../../../components/LayoutCommponents";
import Modal from "../../../components/Modal"
import styles from "./Takeout_Sales.module.scss";
import TColor from '../../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../../styles/TextStyled";

AOS.init({disable: true});

const Takeout_Sales = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	
	useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					포장판매 활성화 방안
				</div>
			</div>
			<div className={styles.Introduce}>
				<div className={styles.InfoText}>
					
					<H1 className={styles.LineBreak} bold color={TColor.black}>
						포장판매 활성화를 위한 각종 방안</H1>
					<VerticalInterval height={screenResponse ? 4 : 2}/>
					<Body2>
						가. SNS활용 : 블로그 인스타 유투브를 통한 마케팅 홍보<br/>
						나. 특별한 반찬류의 개발<br/>
						다. 방문객을 통한 판매
					</Body2>
				</div>
			</div>
			{/*<div className={styles.Introduce}>*/}
			{/*	<div className={styles.InfoText}>*/}
			{/*		{*/}
			{/*			screenResponse ?*/}
			{/*				<H1 className={styles.LineBreak} bold color={TColor.black}>*/}
			{/*					프로그램 안내</H1>*/}
			{/*				:*/}
			{/*				<H1 className={styles.LineBreak} bold color={TColor.black}>*/}
			{/*					프로그램 안내</H1>*/}
			{/*		}*/}
			{/*		<VerticalInterval height={screenResponse ? 4 : 2}/>*/}
			{/*		{*/}
			{/*			screenResponse ?*/}
			{/*				<Body2 className={styles.LineBreak} bold color={TColor.gray500}>*/}
			{/*					전통문화는 한 나라에서 발생하여 오랜 세월에 걸쳐 이어져 내려오는 우리의 고유한 가치라고 할 수 있습니다.*/}
			{/*					<br/>*/}
			{/*					<br/>*/}
			{/*					전통문화를 계승 한다는 것은 우 리의 과거와 현재 미래를 연결하는 수단입니다.*/}
			{/*					<br/>*/}
			{/*					우리 민족의 정체성을 찾아가고 알리는 길이기도 합니다. 그렇기 때문에 전통문화를 바탕으로 한 컨텐츠 개발은 각 지자체의 숙제이기도 합니다.*/}
			{/*					이에 강진군에서는 예로부터 한국 음식의 하나인 사찰음식의 문화적 가치와 전통성을 바탕으로 하는 강진사찰음식체험관을 설립했습니다.*/}
			{/*					<br/>*/}
			{/*					<br/>*/}
			{/*					전형적인 농어촌 지역인 강진군은 예로부터 인심 좋고 살기 좋은 곳으로 이름이 높습니다.*/}
			{/*					동백꽃 피는 남쪽 땅 탐진강이 젖줄기처럼 들판을 적시고 흐르기에 기름지고 오곡과 백과가 풍요로운 이 고을을*/}
			{/*					더욱 아름답게 가꾸고 돋보여서 많은 이들의 발길을 이끄는 원동력이 되고 있습니다.*/}
			{/*					<br/>*/}
			{/*					<br/>*/}
			{/*					다양한 문화를 지니고 발전시켜가고 있는 강진군에 또 하나의 새로운 문화로써 사찰음식체험관을 통해*/}
			{/*					좀 더 발전된 음식문화를 알 릴 수 있는 곳으로 만들어 나아가고자 합니다.*/}
			{/*				</Body2>*/}
			{/*				:*/}
			{/*				<Body1 className={styles.LineBreak} color={TColor.gray700}>*/}
			{/*					전통문화는 한 나라에서 발생하여 오랜 세월에 걸쳐 이어져 내려오는 우리의 고유한 가치라고 할 수 있습니다.*/}
			{/*					<br/>*/}
			{/*					전통문화를 계승 한다는 것은 우 리의 과거와 현재 미래를 연결하는 수단입니다.*/}
			{/*					<br/>*/}
			{/*					우리 민족의 정체성을 찾아가고 알리는 길이기도 합니다. 그렇기 때문에 전통문화를 바탕으로 한 컨텐츠 개발은 각 지자체의 숙제이기도 합니다.*/}
			{/*					이에 강진군에서는 예로부터 한국 음식의 하나인 사찰음식의 문화적 가치와 전통성을 바탕으로 하는 강진사찰음식체험관을 설립했습니다.*/}
			{/*					<br/>*/}
			{/*					전형적인 농어촌 지역인 강진군은 예로부터 인심 좋고 살기 좋은 곳으로 이름이 높습니다.*/}
			{/*					동백꽃 피는 남쪽 땅 탐진강이 젖줄기처럼 들판을 적시고 흐르기에 기름지고 오곡과 백과가 풍요로운 이 고을을 더욱 아름답게 가꾸고 돋보여서 많은 이들의 발길을 이끄는 원동력이 되고*/}
			{/*					있습니다.*/}
			{/*					<br/>*/}
			{/*					다양한 문화를 지니고 발전시켜가고 있는 강진군에 또 하나의 새로운 문화로써 사찰음식체험관을 통해*/}
			{/*					좀 더 발전된 음식문화를 알 릴 수 있는 곳으로 만들어 나아가고자 합니다.*/}
			{/*				</Body1>*/}
			{/*		}*/}
			{/*	</div>*/}
			{/*</div>*/}
		</div>
	);
}


export default Takeout_Sales;
