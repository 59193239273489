import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {HorizontalRule} from "../../components/LayoutCommponents";
import Modal from "../../components/Modal"
import styles from "./Regulation.module.scss";
import TColor from '../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../styles/TextStyled";
AOS.init({disable: true});

const Regulation = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	const tdStyle = { border: "1px solid black" }; // td 스타일 객체
	useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					민간자격 관리운영규정
				</div>
			</div>
			<div className={styles.backGround}>
				<H1 className={styles.title} bold color={TColor.black}>
					자격기본법 제2조 (민간자격의 등록) ② 제1항제1호의 민간자격의 관리·운영에 관
					한 규정에는 다음 각 호의 사항이 포함되어야 한다.
				</H1>
				<div className={styles.Rule}>
					<div className={styles.head}>
						1) 자격의 명칭, 종목 및 등급
					</div>
					<HorizontalRule width={'60%'} height={5}/>
					<div className={styles.texts}>
						제 1 조 (목적)
						<br/>
						이 규정은 사단법인 홍승스님의 사찰음식연구회에서 시행하는
						전문가자격증 1,2,3급의 자격검정의 관리.운영에 필요한 사항을 규정함을
						목적으로 한다.
					</div>
				</div>
				<div className={styles.Rule}>
					<div className={styles.head}>
						2) 자격의 검정을 담당할 인력의 보유 현황
					</div>
					<HorizontalRule width={'60%'} height={5}/>
					<div className={styles.texts}>
						제 2 조 (검정인력검정조직의 업무분장)
						<br/>
						본 협회는 검정관리팀장을 두어 검정
						관리 전반을 담당하도록 하며, 검정관리담당을 두어 자격검정을 운영한
						다.
					</div>
				</div>
				<div className={styles.Rule}>
					<div className={styles.head}>
						3) 자격의 관리·운영조직에 관한 사항
					</div>
					<HorizontalRule width={'60%'} height={5}/>
					<div className={styles.texts}>
						제 3 조 (검정인력)
						<br/>
						본 협회는 검정관리팀장을 두어 검정관리 전반을 담당하도
						록 하며, 팀장 이하 검정기획담당, 검정관리담당을 두어 자격검정을 운영
						한다.
					</div>
					<br/>
					<div className={styles.texts}>
						제4 조 (검정조직의 업무분장) 검정관리팀은 다음과 같이 업무를 분담하여 수
						행한다.
						<br/><br/>
						① 검정기획담당자는 다음 각 호의 업무를 수행한다.<br/>
						1. 검정 시행계획의 수립 및 공고 등에 관한 사항<br/>
						2. 원서접수․시험장소 및 시험감독 등에 관한 사항<br/>
						3. 국가기술자격취득자 관리 및 자격증 교부∙관리에 관한 사항<br/>
						4. 검정업무 지도․감독에 관한 사항<br/>
						5. 검정업무 제도개선에 관한 사항<br/><br/>
						③ 검정관리 담당자는 다음 각 호의 업무를 수행한다.<br/>
						1. 자격취득자 관리 및 자격증 교부․관리에 관한 사항<br/>
						2. 자격취득자 사후관리에 관한 사항<br/>
					</div>
				</div>
					<br/>
			<div className={styles.Rule}>
					<div className={styles.head}>
						4) 자격의 검정기준·검정과목·검정방법·응시자격 및 유효기간에 관한 사항
					</div>
					<HorizontalRule width={'60%'} height={5}/>
					<div className={styles.texts}>
						제 5 조 (검정기준)
						<br/>
						①본 협회는 자격증 활용능력이 사찰음식전문가로써 교육
						과 봉사활동, 강의관련업무를 원활하게 수행할 수 있는 직무능력을 갖추
						고 있는지 유무를 기준으로 하여 등급별 검정기준을 정한다.
						<br/>
						② 사찰음식전문가자격증의 등급별 검정기준은 다음과 같다.
						<table className={styles.table}>
							<thead>
							<tr>
								<th>자격종목</th>
								<th>등급</th>
								<th colSpan={2}>검 정 기 준</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td rowSpan={3} style={{textAlign:'center'}}>
									사찰음식
									전문가
									자격증
								</td>
								<td>1급</td>
								<td>사찰음식문화가 가지고 있는 문화적 가치를 위한 학술 및 연
									구, 보급 사업, 사찰음식 관련된 프로그램 개발 사업등을 수행
									할 능력이 있는 고급 수준
								</td>
							</tr>
							<tr>
								<td>2급</td>
								<td>준전문가 수준의 전문가를 양성할 교육 활용능력을 가지고 있
									으며 전시회 등을 통해 불교문화를 전파할 책임자로써 갖추어
									야 할 능력을 갖춘 고급 수준</td>
							</tr>
							</tbody>
						</table>
					</div>
				<div className={styles.texts}>
					제6조 (검정방법 및 검정과목)
					<br/>
					사찰음식전문가 자격증의 검정과목과 과
					목별 주요내용은 다음과 같다.
					<br/>
					<table className={styles.table}>
						<thead>
						<tr>
							<th>등급</th>
							<th colSpan={2}>검정 방법</th>
							<th>검정 과목(분야 또는 영역)</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td>
								1,2급
							</td>
							<td>필기</td>
							<td>객관식</td>
							<td>
								사찰음식의 특징과 목적
							</td>
						</tr>
						<tr>
							<td>2급</td>
							<td>실기</td>
							<td>실습형</td>
							<td>
								음식 전시회를 통란 조리기술
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div className={styles.texts}>
					제7조 (응시자격)
					<br/>
					본회에서 시행하는 자격검정에 응시를 희망하는 자는
					소정의 교육을 통해 응시 할 수 있다.
				</div>
			</div>
			</div>
		</div>
	);
}


export default Regulation;
