import React, { useEffect, useState } from 'react';
import {VerticalInterval, HorizontalRule} from "../../components/LayoutCommponents";
// import GoogleMap from '../lib/GoogleMap';
import TColor from '../../styles/TimoStyle';
import {Body2, H1} from "../../styles/TextStyled";
import HomeStyle from "./Home.module.scss";
import {Footer} from "../../components/Footer";
import {Link} from "react-router-dom";

// 도반 소개
import doban01 from "../../images/assets/doban/01.jpg"
import doban02 from "../../images/assets/doban/02.jpg"
import doban03 from "../../images/assets/doban/03.jpg"
import doban04 from "../../images/assets/doban/04.jpg"
import doban05 from "../../images/assets/doban/05.png"

//가게 실내 이미지
import store01 from "../../images/assets/doban/store01.jpg"
import store02 from "../../images/assets/doban/store02.jpg"
import store03 from "../../images/assets/doban/store03.jpg"
import tea01 from "../../images/assets/doban/tea01.jpeg"
import tea02 from "../../images/assets/doban/tea02.jpeg"
import room_8 from  "../../images/assets/doban/room_8.jpeg"

const Home = () => {
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const [screenResponse, setScreenResponse] = useState(true);
	const [modalOn, setModalOn] = useState(false);
	
	useEffect(() => {
		updateWindowResize();
		window.addEventListener('resize', updateWindowResize);
		window.addEventListener('scroll', scrollEvent);
		return () => {
			window.removeEventListener('resize', updateWindowResize);
			window.removeEventListener('scroll', scrollEvent);
		}
	}, []);
	
	const updateWindowResize = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
		window.innerWidth > 748 ? setScreenResponse(true) : setScreenResponse(false);
	}
	
	const scrollEvent = () => {
		var myNav1 = document.getElementById('mynav1');
		var myNav2 = document.getElementById('mynav2');
		var myNav3 = document.getElementById('mynav3');
		var myNav4 = document.getElementById('mynav4');
		var myNav5 = document.getElementById('mynav5');
		
		//inview 추가 inview = 글자가 나타나는 효과
		//['chapter_0_1', 'chapter_0_2', 'chapter_0_3', 'chapter_0_4'].forEach((v, i) => {
		
		if (document.querySelector(`#chapter_0_1`).getClientRects()[0].top < 300 &&
			document.querySelector(`#chapter_0_1`).getClientRects()[0].top > -450) {
			myNav1.setAttribute('title', 'check');
		} else {
			myNav1.removeAttribute('title');
		}
		
		
		if (document.querySelector(`#chapter_0_2`).getClientRects()[0].top < 300 &&
			document.querySelector(`#chapter_0_2`).getClientRects()[0].top > -450) {
			myNav2.setAttribute('title', 'check');
		} else {
			myNav2.removeAttribute('title');
		}
		
		if (document.querySelector(`#chapter_0_3`).getClientRects()[0].top < 300 &&
			document.querySelector(`#chapter_0_3`).getClientRects()[0].top > -450) {
			myNav3.setAttribute('title', 'check');
		} else {
			myNav3.removeAttribute('title');
		}
		
		if (document.querySelector(`#chapter_0_4`).getClientRects()[0].top < 300 &&
			document.querySelector(`#chapter_0_4`).getClientRects()[0].top > -450) {
			myNav4.setAttribute('title', 'check');
		} else {
			myNav4.removeAttribute('title');
		}
		if (document.querySelector(`#chapter_0_5`).getClientRects()[0].top < 300 &&
			document.querySelector(`#chapter_0_5`).getClientRects()[0].top > -450) {
			myNav5.setAttribute('title', 'check');
		} else {
			myNav5.removeAttribute('title');
		}
	}
	return (
		<div style={{width: '100%'}}>
			<div className={HomeStyle.homeMain}>
				<div className={HomeStyle.homeMainVisual}>
					<div className={HomeStyle.visualContent}>
						<div className={HomeStyle.visualText}>
              <span>
                <h1>전통사찰음식점 도반</h1>
              </span>
							<VerticalInterval height={1}/>
							<span>
								<div className={HomeStyle.h2title}>
			            FMD 식단
								</div>
								<br/>
								<div className={HomeStyle.h2title}>
			            천연 식재료
								</div>
								<br/>
								<div className={HomeStyle.h2title}>
			            건강과 행복
								</div>
              </span>
						</div>
						<VerticalInterval height={4}/>
					</div>
				</div>
				
				<div id={'serviceIntroduction'} className={HomeStyle.serviceIntroduction}>
					<div className={HomeStyle.serviceTitle}>
						{screenResponse ? <H1 bold color={TColor.black}>사찰음식 도반은</H1>
							: <H1 center bold color={TColor.black}>사찰음식 도반은</H1>}
					</div>
					<div className={HomeStyle.serviceFloor}>
						<div className={HomeStyle.serviceItem}>
							<div className={HomeStyle.serviceItemText}>
								<div className={HomeStyle.lineAni}>
									<h2>건강한 음식문화</h2>
								</div>
								<Body2>
									건강한 음식문화인 사찰음식을 통하여 사람들에게 제철음식으로, 최고의 조리법으로, 최고의 건강식으로 다가서고자 합니다.
									<br/>
									<br/>
									콩을 통한 양질의 단백질 섭취, 각종 식물성 기름을 통한 불포화 지방산의 섭취, 다양한 채소로부터 풍부한 무기질, 섬유소, 약용성분을 충분히 섭취하는 놀라운 식단 입니다.
								</Body2>
							</div>
							<div className={HomeStyle.serviceImg}>
								<img alt={''}
								     src={'images/home-img-1.png'}/>
							</div>
						</div>
						<div className={HomeStyle.serviceItem}>
							<div className={HomeStyle.serviceItemText}>
								<div className={HomeStyle.lineAni}>
									<h2>순수 채식</h2>
								</div>
								<Body2>
									육류를 전혀 사용하지 않을 뿐 아니라 향이 강한 오신채도 역시 피합니다.
									<br/>
									<br/>
									순수 채식이면서도 식물성 식품의 다양한 배합과 조리, 가공을 통해 창조하는 독특한 맛이 있습니다.
								</Body2>
							</div>
							<div className={HomeStyle.serviceImg}>
								<img alt={''}
								     src={'images/home-img-2.png'}/>
							</div>
						</div>
					</div>
					<div className={HomeStyle.serviceFloor}>
						<div className={HomeStyle.serviceItem}>
							<div className={HomeStyle.serviceItemText}>
								<div className={HomeStyle.lineAni}>
									<h2>천연 재료</h2>
								</div>
								<Body2>
									사찰음식에는 인공조미료를 쓰지 않고, 자연재료로 만든 천연조미료를 사용합니다.
									<br/>
									<br/>
									천연조미료들은 오늘날 만연하고 있는 각종 화학조미료의 폐해로부터 사람들의 건강을 보호하는 가장 유력한 대안으로 주목 받고 있습니다.
								</Body2>
							</div>
							<div className={HomeStyle.serviceImg}>
								<img alt={''}
								     src={'images/home-img-3.png'}/>
							</div>
						</div>
						<div className={HomeStyle.serviceItem}>
							<div className={HomeStyle.serviceItemText}>
								<div className={HomeStyle.lineAni}>
									<h2>1700년의 역사</h2>
								</div>
								<Body2>
									최근 들어 성인병 예방과 치료를 위한 건강식에 높은 관심이 생겨나며 채식과 사찰음식의 고유한 조리법과 음식들이 주목을 받고 있습니다.
									<br/>
									<br/>
									1,700년 동안 일상적으로 먹어오던 재료나 조리법의 효과를 검증한 사찰음식은 새롭게 발견된 오래된 미래입니다.
								</Body2>
								<Link to="/dobanfood/menu"
								      style={{marginRight: '5%', textDecoration: 'none', color: 'inherit'}}>
									<div className={HomeStyle.CompanyLink}>
										메뉴 보기 >
									</div>
								</Link>
							</div>
							
							{/*<div className={HomeStyle.mobileImg}>*/}
							{/*	<Link to="/Company"*/}
							{/*	      style={{marginRight: '5%', textDecoration: 'none', color: 'inherit'}}>*/}
							{/*		<div className={HomeStyle.CompanyLink}>*/}
							{/*			자세히 보기 >*/}
							{/*		</div>*/}
							{/*	</Link>*/}
							{/*	<div className={HomeStyle.serviceImg}>*/}
							{/*		<img alt={''}*/}
							{/*		     src={'images/home-img-4.png'}/>*/}
							{/*	</div>*/}
							{/*</div>*/}
							{/*{screenResponse &&*/}
							{/*<div className={HomeStyle.serviceImg}>*/}
							{/*	<img alt={''}*/}
							{/*	     src={'images/home-img-4.png'}/>*/}
							{/*</div>*/}
							{/*}*/}
						</div>
					</div>
				</div>
				<div className={HomeStyle.how_to_use}>
					<div className={HomeStyle.instructionsTitle}>
						<h3>
							도반의 사찰음식은
							<br/>
							<div style={{display:'flex',flexFlow:'row nowrap'}}>
								<div style={{color:'rgb(118,157,107)'}}>건강</div>입니다
							</div>
						</h3>
						
					</div>
					<div className={HomeStyle.chapter__items}>
						<div id={'chapter_0_1'} className={HomeStyle.chapter__item}>
							<div className={HomeStyle.context_reverse}>
								<div className={HomeStyle.context_text}>
									<div className={HomeStyle.text_sizeReply}>
										<h1>육류를 사용하지 않으며 </h1>
										<p style={{fontWeight: 'bold'}}>향이 강한 오신채(마늘, 파, 양파, 달래, 흥거)도 사용하지 않습니다.</p>
										<p style={{color: '#616161'}}>순수 채식이면서도 식물성 식품의 다양한 배합과 조리, 가공을 통해 창조하는 독특한 맛이 있습니 다. </p>
									</div>
								</div>
								<div className={HomeStyle.context_img}>
									<img src={doban01} alt={'01이미지'}/>
								</div>
							</div>
						</div>
						<div id={'chapter_0_2'} className={HomeStyle.chapter__item}>
							<div className={HomeStyle.context}>
								<div className={HomeStyle.context_img}>
									<img src={doban02} alt={'02이미지'}/>
								</div>
								<div className={HomeStyle.context_text}>
									<div className={HomeStyle.text_sizeReply}>
										<h1>특별하고 색다른 채식 코스요리입니다.</h1>
										<p style={{fontWeight: 'bold'}}>음식은 사람을 지탱하는 중요한 요소이자 삶의 한부분 입니다</p>
										<p style={{color: '#616161'}}>건강과 행복의 식생활이 사찰음식에 잇습니다.</p>
									</div>
								</div>
							
							</div>
						</div>
						<div id={'chapter_0_3'} className={HomeStyle.chapter__item}>
							<div className={HomeStyle.context_reverse}>
								<div className={HomeStyle.context_text}>
									<div className={HomeStyle.text_sizeReply}>
										<h1>좋은 재료로 정성을 다해 조리합니다.</h1>
										<p style={{fontWeight: 'bold'}}>매일 새벽 시장에서 공수해오는 신선한 재료와</p>
										<p style={{color: '#616161'}}>직접 담근 된장과 간장을 사용합니다.</p>
									</div>
								</div>
								<div className={HomeStyle.context_img}>
									<img src={doban03} alt={'03이미지'}/>
								</div>
							</div>
						</div>
						<div id={'chapter_0_4'} className={HomeStyle.chapter__item}>
							<div className={HomeStyle.context}>
								<div className={HomeStyle.context_img}>
									<img src={doban04} alt={'04이미지'}></img>
								</div>
								<div className={HomeStyle.context_text}>
									<div className={HomeStyle.text_sizeReply}>
										<h1>인공조미료는 일체 사용하지 않습니다.</h1>
										<p style={{fontWeight: 'bold'}}>재료 본연의 맛을 살려낸 방식으로 대접받은 한끼를 즐겨보세요.</p>
										<p style={{color: '#616161'}}>사찰에서 사용하는 천연조미료로는 버섯가루, 다시마가루, 제피가루, 들깨가루, 날콩가
											루 등이 대표적입니다.</p>
									</div>
								</div>
							</div>
						</div>
						<VerticalInterval height={4}/>
						<div id={'chapter_0_5'} className={HomeStyle.chapter__item}>
							<div className={HomeStyle.context}>
								<div className={HomeStyle.context_img}>
									<img src={doban05} alt={'05이미지'}></img>
								</div>
								<div className={HomeStyle.context_text}>
									<div className={HomeStyle.text_sizeReply}>
										<h1>사찰음식 조리법 그대로 조리합니다.</h1>
										<p style={{fontWeight: 'bold'}}>일상적으로 먹어오던 재료나 조리법은</p>
										<p style={{color: '#616161'}}>새롭게 발견된 오래된 미래입니다.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<nav className={HomeStyle.chapter__navigation}>
						<div className={HomeStyle.chapter__navigation_inner}>
							<a id={'mynav1'} className={HomeStyle.nav_item} href={'#chapter_0_1'} title={1}>
								<h3>1</h3>
							</a>
							<a id={'mynav2'} className={HomeStyle.nav_item} href={'#chapter_0_2'} title={2}>
								<h3>2</h3>
							</a>
							<a id={'mynav3'} className={HomeStyle.nav_item} href={'#chapter_0_3'} title={3}>
								<h3>3</h3>
							</a>
							<a id={'mynav4'} className={HomeStyle.nav_item} href={'#chapter_0_4'} title={4}>
								<h3>4</h3>
							</a>
							<a id={'mynav5'} className={HomeStyle.nav_item} href={'#chapter_0_5'} title={5}>
								<h3>5</h3>
							</a>
						</div>
					</nav>
				</div>
				<VerticalInterval height={3}/>
				<div className={HomeStyle.premiumService}>
					<div className={HomeStyle.premiumTitle}>
						<h2>사찰음식전문점 도반</h2>
						<h1>시설이용안내</h1>
					</div>
					<div className={HomeStyle.premiumContents}>
						<HorizontalRule height={3}/>
						<div className={HomeStyle.facilityInfo}>
							12인 단체룸
						</div>
						<div className={HomeStyle.premiumContentsItem}>
							<img alt={''}
							     src={store01}/>
						</div>
						<HorizontalRule height={3}/>
						
						<div className={HomeStyle.premiumContentsItem}>
							<img alt={''} src={store02}/>
						</div>
						<HorizontalRule height={3}/>
						<div className={HomeStyle.facilityInfo}>
							8인석
						</div>
						<div className={HomeStyle.premiumContentsItem}>
							<img alt={''} src={room_8}/>
						</div>
						<HorizontalRule height={3}/>
						{/*<div className={HomeStyle.facilityInfo}>*/}
						{/*	조리 공간*/}
						{/*</div>*/}
						{/*<div className={HomeStyle.premiumContentsItem}>*/}
						{/*	<img alt={''} src={store03}/>*/}
						{/*</div>*/}
						<div className={HomeStyle.facilityInfo}>
							차방
						</div>
						<div className={HomeStyle.premiumContentsItem}>
							<img alt={''} src={tea01}/>
						</div>
						<div className={HomeStyle.premiumContentsItem}>
							<img alt={''} src={tea02}/>
						</div>
					</div>
					<VerticalInterval height={5}/>
				</div>
			</div>
		</div>
	);
}


export default Home;
