import React, { useState,useEffect } from 'react';
import styles from './FAQ.module.scss';
import Pagination from 'react-js-pagination';
import "./Paging.css";
import { useLocation } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const FAQ = () => {
	const [activeQuestion, setActiveQuestion] = useState(-1);
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const [selectedCategory,setSelectedCategory ] = useState(params.get('category') || '전체');
	
	//원본 질문
	const [questions, setQuestions] = useState([
		{
			question: '수강신청은 언제부터 언제까지 가능한가요?',
			answer: '안녕하세요.\n' +
				'\n' +
				'강진 사찰음식문화체험관입니다.\n' +
				'\n' +
				'\n' +
				'매월 1일(휴관일, 공휴일인 경우 다음날) 오전 10시부터 다음달 수업 수강신청이 가능합니다. 보통 1~2주 내로 마감되니 참고하여 신청바랍니다.\n' +
				'\n' +
				'\n' +
				'감사합니다.',
			category: '수강신청'
		},
		{
			question: '사찰음식 프로그램에 대하여 상담하고 싶습니다.',
			answer: '안녕하세요.\n' +
				'\n' +
				'강진 사찰음식문화체험관입니다.\n' +
				'\n' +
				'\n' +
				'상담을 원하시는 경우에는 아래와 같은 방법으로 진행해 주시면 됩니다.\n' +
				'\n' +
				'전화상담 및 직접방문 : 9:00~18:00   Tel) 02-xxx-xxxx\n' +
				'\n' +
				'                          (점심시간 12:00~13:00, 수업일정에 따라 변동)\n' +
				'\n' +
				'이메일 : xxx.com\n' +
				'\n' +
				'홈페이지 문의 : http:// → Q&A 문의 \n' +
				'\n' +
				'\n' +
				'감사합니다.',
			category: '수강신청'
		},
		{
			question: '요리초보자인데 수강에 문제가 없을까요?',
			answer: '안녕하세요.\n' +
				'\n' +
				'강진 사찰음식문화체험관입니다.\n' +
				'\n' +
				'\n' +
				'모든 수강생들을 위해 기초부터 차근차근 설명해드립니다.\n' +
				'\n' +
				'걱정하지 않으셔도 됩니다. 편안한 마음으로 신청해주세요.\n' +
				'\n' +
				'\n' +
				'감사합니다.',
			category: '수강신청'
		},
		{
			question: '수업 준비물은 무엇이 필요한가요?',
			answer: '안녕하세요.\n' +
				'강진사찰음식문화체험관입니다.\n' +
				'\n' +
				'개인행주 2개, 시식 후 남은 음식 담아갈 용기를 준비물로 가져오시기 바랍니다. 필기도구와 앞치마는 체험관에 준비되어 있습니다. \n' +
				'\n' +
				'감사합니다.',
			category: '수강신청'
		},
		{
			question: '입금은 언제까지 해야 하나요?',
			answer:
				'안녕하세요.\n' +
				'\n' +
				'강진 사찰음식문화체험관입니다.\n' +
				'\n' +
				'\n' +
				'수업 신청 접수 후 3일 이내로 입금해주셔야 합니다. 미입금시에는 자동으로 취소되는 점 유의해 주시기 바랍니다. \n' +
				'\n' +
				'\n' +
				'감사합니다.',
			category: '입금 및 환불'
		},
		{
			question: '사찰음식에 오신채를 사용하지 않는 이유는 무엇인가요?',
			answer:
				'오신채는 다섯 가지 매운맛이 나는 채소로서 진심(瞋心: 노여움의 마음)과 음심(淫心: 음란한 마음)을 일으킨다고 하여 불교에서는 섭취를 금해온 전통이 있습니다. 한편으로 오신채는 냄새가 강해 수행생활을 하는 다른 사람들에게 불편을 끼치기도 하여 금하는 것입니다.',
			category: '프로그램 문의'
		},
		{
			question: '사찰음식은 채식과 어떻게 다른가요?',
			answer:
				'사찰음식은 기본적으로 일체의 동물성음식을 먹지 않는 것은 일반채식과 같으나, 오신채(파, 마늘, 부추, 달래, 흥거)를 사용하지 않는 특징이 있습니다. 하지만 단순히 이 오신채를 넣지 않는 것이 일반채식과의 차이점은 아닙니다.\n' +
				'\n' +
				'사찰음식은 모든 생명에 대한 자비심의 발로에서 나온 것이며, 생산과 수확· 조리와 섭생에 이르기까지 모든 과정이 수행의 일환인 것입니다. 그래서 음식이되 음식의 기본개념을 넘어, 정신을 맑게 하여 수행에 도움을 주는 좋은 약으로 여깁니다.\n' +
				'\n' +
				'법당의 부처님께 올리는 공양을 준비하듯이 정성껏 음식을 만들어 사부대중이 평등하게 나누어먹고, 부지런히 수행 정진하여 지혜를 얻기 위해 먹는 수행식이라는 점이 바로 일반채식과 구별되는 진정한 의미의 사찰음식이라고 하겠습니다.\n' +
				'\n' +
				'\n',
			category: '기타 문의'
		},
	]);
	// 필터링 되는 질문
	const [viewQuestions, setViewQuestions] = useState([
		{
			question: '수강신청은 언제부터 언제까지 가능한가요?',
			answer: '안녕하세요.\n' +
				'\n' +
				'강진 사찰음식문화체험관입니다.\n' +
				'\n' +
				'\n' +
				'매월 1일(휴관일, 공휴일인 경우 다음날) 오전 10시부터 다음달 수업 수강신청이 가능합니다. 보통 1~2주 내로 마감되니 참고하여 신청바랍니다.\n' +
				'\n' +
				'\n' +
				'감사합니다.',
			category: '수강신청'
		},
		{
			question: '사찰음식 프로그램에 대하여 상담하고 싶습니다.',
			answer: '안녕하세요.\n' +
				'\n' +
				'강진사찰음식문화체험관입니다.\n' +
				'\n' +
				'\n' +
				'상담을 원하시는 경우에는 아래와 같은 방법으로 진행해 주시면 됩니다.\n' +
				'\n' +
				'전화상담 및 직접방문 : 9:00~18:00   Tel) 02-xxx-xxxx\n' +
				'\n' +
				'                          (점심시간 12:00~13:00, 수업일정에 따라 변동)\n' +
				'\n' +
				'이메일 : xxx.com\n' +
				'\n' +
				'홈페이지 문의 : http:// → Q&A 문의 \n' +
				'\n' +
				'\n' +
				'감사합니다.',
			category: '수강신청'
		},
		{
			question: '요리초보자인데 수강에 문제가 없을까요?',
			answer: '안녕하세요.\n' +
				'\n' +
				'강진사찰음식문화체험관입니다.\n' +
				'\n' +
				'\n' +
				'모든 수강생들을 위해 기초부터 차근차근 설명해드립니다.\n' +
				'\n' +
				'걱정하지 않으셔도 됩니다. 편안한 마음으로 신청해주세요.\n' +
				'\n' +
				'\n' +
				'감사합니다.',
			category: '수강신청'
		},
		{
			question: '수업 준비물은 무엇이 필요한가요?',
			answer: '안녕하세요.\n' +
				'강진사찰음식문화체험관입니다.\n' +
				'\n' +
				'개인행주 2개, 시식 후 남은 음식 담아갈 용기를 준비물로 가져오시기 바랍니다. 필기도구와 앞치마는 체험관에 준비되어 있습니다. \n' +
				'\n' +
				'감사합니다.',
			category: '수강신청'
		},
		{
			question: '입금은 언제까지 해야 하나요?',
			answer:
				'안녕하세요.\n' +
				'\n' +
				'강진 사찰음식문화체험관입니다.\n' +
				'\n' +
				'\n' +
				'수업 신청 접수 후 3일 이내로 입금해주셔야 합니다. 미입금시에는 자동으로 취소되는 점 유의해 주시기 바랍니다. \n' +
				'\n' +
				'\n' +
				'감사합니다.',
			category: '입금 및 환불'
		},
		{
			question: '사찰음식에 오신채를 사용하지 않는 이유는 무엇인가요?',
			answer:
				'오신채는 다섯 가지 매운맛이 나는 채소로서 진심(瞋心: 노여움의 마음)과 음심(淫心: 음란한 마음)을 일으킨다고 하여 불교에서는 섭취를 금해온 전통이 있습니다. 한편으로 오신채는 냄새가 강해 수행생활을 하는 다른 사람들에게 불편을 끼치기도 하여 금하는 것입니다.',
			category: '프로그램 문의'
		},
		{
			question: '사찰음식은 채식과 어떻게 다른가요?',
			answer:
				'사찰음식은 기본적으로 일체의 동물성음식을 먹지 않는 것은 일반채식과 같으나, 오신채(파, 마늘, 부추, 달래, 흥거)를 사용하지 않는 특징이 있습니다. 하지만 단순히 이 오신채를 넣지 않는 것이 일반채식과의 차이점은 아닙니다.\n' +
				'\n' +
				'사찰음식은 모든 생명에 대한 자비심의 발로에서 나온 것이며, 생산과 수확· 조리와 섭생에 이르기까지 모든 과정이 수행의 일환인 것입니다. 그래서 음식이되 음식의 기본개념을 넘어, 정신을 맑게 하여 수행에 도움을 주는 좋은 약으로 여깁니다.\n' +
				'\n' +
				'법당의 부처님께 올리는 공양을 준비하듯이 정성껏 음식을 만들어 사부대중이 평등하게 나누어먹고, 부지런히 수행 정진하여 지혜를 얻기 위해 먹는 수행식이라는 점이 바로 일반채식과 구별되는 진정한 의미의 사찰음식이라고 하겠습니다.\n' +
				'\n' +
				'\n',
			category: '기타 문의'
		},
	]);

// 필터링 되는 질문
	const [activePage, setActivePage] = useState(1);
	const pageSize = 5;
	
	useEffect(() => {
		if(selectedCategory === '전체'){
			setViewQuestions(questions.slice(0, pageSize));
		}
		else if (selectedCategory) {
			// filter questions based on the selected category
			const filteredQuestions = questions.filter(
				question => question.category === selectedCategory
			);
			setViewQuestions(filteredQuestions.slice(0, pageSize));
			// questions자체를 날려버려서 문제다.
		}
		else {
			setViewQuestions([]);
		}
		setActivePage(1);
	}, [selectedCategory, questions]);
	
	useEffect(() => {
		const startIndex = (activePage - 1) * pageSize;
		const endIndex = startIndex + pageSize;
		if (selectedCategory === '전체') {
			setViewQuestions(questions.slice(startIndex, endIndex));
		} else if (selectedCategory) {
			// filter questions based on the selected category
			const filteredQuestions = questions.filter(
				question => question.category === selectedCategory
			);
			setViewQuestions(filteredQuestions.slice(startIndex, endIndex));
		}
	}, [activePage, questions, selectedCategory]);
	
	const handlePageChange = pageNumber => {
		setActivePage(pageNumber);
	};
	
	const handleClick = (index) => {
		setActiveQuestion(index === activeQuestion ? -1 : index);
	};
	
	const categories = Array.from(new Set(questions.map(question => question.category)));
	const defaultCategories = [
		{ name: '전체', selected: selectedCategory === '전체' },
		{ name: '수강신청', selected: selectedCategory === '수강신청' },
		{ name: '입금 및 환불', selected: selectedCategory === '입금 및 환불' },
		{ name: '프로그램 문의', selected: selectedCategory === '프로그램 문의' },
		{ name: '기타 문의', selected: selectedCategory === '기타 문의' },
	];
	
	const handleCategoryClick = (category) => {
		window.history.pushState({}, '', `?category=${category}`);
		setSelectedCategory(category);
	};
	
	const MenuBar = () => {
		return (
			<div className={styles.menuBar}>
				{defaultCategories.map(category => (
					<button
						key={category.name}
						className={`${styles.menuButton} ${
							category.selected ? styles.active : ''
						}`}
						onClick={() => handleCategoryClick(category.name)}
					>
						{category.name}
					</button>
				))}
			</div>
		);
	};
	
	return (
		<div className={styles.faq}>
			<h1 className={styles.title}>FAQ</h1>
			<ul className={styles.questions}>
				<MenuBar />
				{viewQuestions.map((question, index) => (
					<li key={question.question}>
						<div
							className={`${styles.question} ${
								index === activeQuestion ? styles.active : ''
							}`}
							onClick={() => handleClick(index)}
						>
							<ContactSupportIcon style={{width:40, height:20}}/>
							<h2 className={styles.questionTitle}>{question.question}</h2>
							{index === activeQuestion ?
								<KeyboardArrowDownIcon style={{width:40, height:20}}/>
								:
								<KeyboardArrowUpIcon style={{width:40, height:20}}/>
							}
						</div>
						{index === activeQuestion && (
							<p className={styles.questionAnswer}>
								<QuestionAnswerIcon style={{width:40, height:15}}/>
								{question.answer}
							</p>
						)}
					</li>
				))}
			</ul>
			<div>
				<Pagination
					activePage={activePage}
					itemsCountPerPage={pageSize}
					totalItemsCount={questions.length}
					pageRangeDisplayed={5}
					onChange={handlePageChange}
					itemClass="page-item"
					linkClass="page-link"
					activeClass="active"
					activeLinkClass="active-link"
				/>
			</div>
		</div>
	);
};

export default FAQ;
