import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {VerticalInterval} from "../../components/LayoutCommponents";
import Modal from "../../components/Modal"
import styles from "./Contact.module.scss";
import TColor from '../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../styles/TextStyled";
import KakaoMapScript from "../../components/KakaoMapScript"
AOS.init({disable: true});

const Contact = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	
	useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					찾아오시는 길
				</div>
			</div>
			<div className={styles.backGround}>
				<H1 className={styles.LineBreak} bold color={TColor.black}>
					찾아 오시는 길</H1>
				<Body2>INFOMATION</Body2>
				<div className={styles.serviceUseGuide}>
					<div className={styles.hoursOfUse}>
						<div style={{display: 'flex', flexFlow: 'row nowrap'}}>
							<img alt={''}
							     src={'images/time.png'}/>
							<h2 color={TColor.white}>고객센터</h2>
						</div>
						<div style={{marginLeft: '40px'}}>
							<p color={TColor.white}>T. 061-432-6665</p>
							{/*<p color={TColor.white}> M. QnA 게시판 이용바랍니다.</p>*/}
						</div>
						<div style={{display: 'flex', flexFlow: 'row nowrap', paddingTop: '1rem'}}>
							<img alt={''}
							     src={'images/flag.png'}/>
							<h2 color={TColor.white}>오시는 길</h2>
						</div>
						<div style={{marginLeft: '40px'}}>
							<p color={TColor.white}>
								<Body2 bold color={TColor.white}>주소</Body2>
								전남 강진군 강진오감통 오감길 2 3동 <br/>강진 사찰음식 전문점 도반
							</p>
						</div>
						<VerticalInterval height={1}/>
						<div style={{marginLeft: '40px', color: TColor.white}}>
							<p>
								{/*<Body2 bold color={TColor.white}>주차안내</Body2>*/}
								{/*<br/>*/}
								{/*- 체험관 수강생 전용으로 단순 방문객은 주차가 불가합니다.<br/>*/}
								{/*- SUV차량은 종로경찰서 옆 공영주차장을 이용해주세요.<br/>*/}
								{/*- 주말에는 종로 및 광화문 일대 차량통행이 제한되는 곳이 많으니 대중교통 이용을 권장합니다<br/>*/}
							</p>
						</div>
					</div>
					<div className={styles.googleMap}>
						<KakaoMapScript title={'사찰음식전문점 도반'}/>
					</div>
				</div>
			</div>
		</div>
	);
}


export default Contact;
