import TColor from "../styles/TimoStyle";
import Modal from "./Modal";
import React from "react";
import {HorizontalRule} from "./LayoutCommponents";
import {Caption} from "../styles/TextStyled";
// import {Singleton} from "../lib/Singleton";
import FooterStyle from "./Footer.module.scss";
// import {LanguageChange} from "./LanguageChange";
import logo from "../images/assets/gangjin.png"

export class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {privacyPolicy: '', termsService: '', language: '한국어'};
    }
    
    // 061-432-6665
    render() {
        // const choiceLang = Singleton.getInstance();
        // choiceLang.getTerms('privacyPolicy');
        return (
            <div className={FooterStyle.footerMain}>
                <div className={FooterStyle.logoDiv}>
                    <img className={FooterStyle.logoSize} alt={''} src={logo}/>
                </div>

                <div className={FooterStyle.textContents}>
                    <div className={FooterStyle.responsiveTerms}>
                        <div className={FooterStyle.terms}>
                            {/*<div id={FooterStyle.tag1}>*/}
                            {/*    <Modal modalTitle='개인정보보호'>*/}
                            {/*        /!*<div dangerouslySetInnerHTML={{__html: choiceLang.getTerms('privacyPolicy')}}/>*!/*/}
                            {/*        /!*<text>{'개인정보'}</text>*!/*/}
                            {/*    </Modal>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    <Modal modalTitle='이용약관'>*/}
                            {/*        /!*<div dangerouslySetInnerHTML={{__html: choiceLang.getTerms('termsService')}}/>*!/*/}
                            {/*        /!*<text>{'이용약관'}</text>*!/*/}
                            {/*    </Modal>*/}
                            {/*</div>*/}
                        </div>
                        <div className={FooterStyle.shareIcons} style={{flexFlow: 'column nowrap'}}>
                            <div style={{display: 'flex', paddingBottom: '20px'}}>
                                <a href={"https://www.facebook.com"}
                                   target={"_blank"}>
                                    <img style={{height: '40px', paddingRight: '20px'}} alt={''}
                                         src={'images/footer-facebook.png'}/>
                                </a>
                                <a href={"https://www.instagram.com"}
                                   target={"_blank"}>
                                    <img style={{height: '40px',}} alt={''} src={'images/footer-instagram.png'}/>
                                </a>
                            </div>
                            {/*<LanguageChange/>*/}
                        </div>
                    </div>
                    <div className={FooterStyle.shareIcons}>
                        <HorizontalRule height={13}/>
                    </div>
                    <div style={{display: 'flex', height:'100%', justifyContent:'flex-end', flexFlow: 'column nowrap'}}>
                        <Caption color={TColor.gray500}>대표번호 061-432-6665</Caption>
                        {/*<Caption color={TColor.gray700} style={{paddingTop: '10px'}}>고객문의*/}
                        {/*    support@xxx.com</Caption>*/}
                        {/*<Caption color={TColor.gray700} style={{paddingTop: '10px'}}>제휴문의*/}
                        {/*    partners@xxx.com</Caption>*/}
                        <Caption color={TColor.gray500} style={{paddingTop: '10px'}}>전남 강진군 강진오감통 오감길 2 3동 <br/>강진 사찰음식체험관</Caption>
                        <Caption className={FooterStyle.shareIcons} color={TColor.gray700} style={{paddingTop: '10px'}}>All
                            Rights Reserved.<br/>
                            © 강진사찰음식체험관, Inc.</Caption>
                    </div>
                </div>
                <div className={FooterStyle.FooterVisibility}>
                    <div className={FooterStyle.language}>
                        {/*<LanguageChange/>*/}
                    </div>
                    <div style={{
                        display: 'flex', flexFlow: 'column nowrap', height: '100%', color: TColor.white,
                        alignItems: 'flex-end', justifyContent: 'flex-end'
                    }}>
                        {/*<div style={{display: 'flex', paddingBottom: '20px'}}>*/}
                        {/*    <a href={"https://www.facebook.com"}*/}
                        {/*       target={"_blank"}>*/}
                        {/*        <img style={{height: '40px', paddingRight: '20px'}} alt={''}*/}
                        {/*             src={'images/footer-facebook.png'}/>*/}
                        {/*    </a>*/}
                        {/*    <a href={"https://www.instagram.com"}*/}
                        {/*       target={"_blank"}>*/}
                        {/*        <img style={{height: '40px',}} alt={''} src={'images/footer-instagram.png'}/>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        <Caption style={{textAlign:'right'}} color={TColor.gray500}>All Rights Reserved. © 강진사찰음식체험관, Inc.</Caption>
                    </div>
                </div>
            </div>
        );
    };
}
