import React, { useState, useEffect } from 'react';
import {Body2, H2} from "../styles/TextStyled";
import Hstyle from "./Header.scss"
import { useLocation,Link } from 'react-router-dom';
import logo from "../images/assets/logo.png"
export const Header = () => {
	const [isHeaderVisible, setIsHeaderVisible] = useState(true);
	const [lastScrollPos, setLastScrollPos] = useState(0);
	const location = useLocation();
	let headerTitle;
	let headsub;

	switch (location.pathname) {
		case '/experiencecenter/about':
			headerTitle = '강진사찰음식체험관'
			headsub = 'Gangjin Temple Food Experience Center'
			break;
		case '/dobanfood/about':
			headerTitle = '전통사찰음식점 도반'
			headsub = 'Doban, a temple food restaurant'
			break;
		case '/templefoodsociety/companyhistory':
			headerTitle = '(사)홍승스님의 사찰음식연구회'
			headsub = `Hong Seung's Temple Food Research Society`
			break;
		default:
			headerTitle = ''
			break;
	}
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	},[location.pathname]);
	
	const handleScroll = () => {
		const currentScrollPos = window.pageYOffset;
		if(currentScrollPos == 0)
			setIsHeaderVisible(true)
		else
			setIsHeaderVisible(false)
		// if (currentScrollPos < lastScrollPos && !isHeaderVisible) {
		// 	setIsHeaderVisible(true);
		// } else if (currentScrollPos > lastScrollPos && isHeaderVisible) {
		// 	setIsHeaderVisible(false);
		// }
		setLastScrollPos(currentScrollPos);
	};
	return (
		<div className={`header ${!isHeaderVisible ? 'header--hidden' : ''}`}>
			<nav className={`header ${!isHeaderVisible ? 'header--hidden' : ''}`}>
				<div className="header_nav">
					<div className={location.pathname === '/experiencecenter/about' ? 'active' : ''}>
						<Link style={{
							color : location.pathname === '/experiencecenter/about' ? "green" : '#333',
							fontWeight : location.pathname === '/experiencecenter/about' ? "bold" : 'normal',
							textDecoration : location.pathname === '/experiencecenter/about' ? "underline" : 'none',
							backgroundColor : location.pathname === '/experiencecenter/about' ? "#ccc" : '#eee',
						}}  to='/experiencecenter/about'>강진사찰음식체험관</Link>
					</div>
					<div style={{marginRight:'3px',marginLeft:'3px',color:'black'}}>|</div>
					<div className={location.pathname === '/dobanfood/about' ? 'active' : ''}>
						<Link style={{
							color : location.pathname === '/dobanfood/about' ? "green" : '#333',
							fontWeight : location.pathname === '/dobanfood/about' ? "bold" : 'normal',
							textDecoration : location.pathname === '/dobanfood/about' ? "underline" : 'none',
							backgroundColor : location.pathname === '/dobanfood/about' ? "#ccc" : '#eee',
						}} to='/dobanfood/about'>전통사찰음식점 도반</Link>
					</div>
					<div style={{marginRight:'3px',marginLeft:'3px',color:'black'}}>|</div>
					<div className={location.pathname === '/templefoodsociety/companyhistory' ? 'active' : ''}>
						<Link style={{
							color : location.pathname === '/templefoodsociety/companyhistory' ? "green" : '#333',
							fontWeight : location.pathname === '/templefoodsociety/companyhistory' ? "bold" : 'normal',
							textDecoration : location.pathname === '/templefoodsociety/companyhistory' ? "underline" : 'none',
							backgroundColor : location.pathname === '/templefoodsociety/companyhistory' ? "#ccc" : '#eee',
						}}
							to='/templefoodsociety/companyhistory'>홍승스님의 사찰음식연구회</Link>
					</div>
				</div>
			</nav>
			<div className={`header-title ${!isHeaderVisible ? 'header-title--hidden' : ''}`}>
					{headerTitle}
					<br/>
					{headsub}
			</div>
		</div>
	);
};

export default Header;
