import React from 'react';


export const VerticalInterval = ({height}) => (
    <div style={{marginTop: `${height}rem`}}/>
);

export const HorizontalRule = ({width, height}) => (
    <div style={{
        width: width ? width : '100%', height: 1,
        marginBottom: `${height}px`,
        marginTop: `${height}px`,
        borderStyle:'solid',
        borderLeftWidth:0,
        borderRightWidth:0,
        borderTopWidth:0,
        borderBottomWidth:1,
        borderColor: '#dddddd',
    }}/>
);
