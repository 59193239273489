import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {HorizontalRule, VerticalInterval} from "../../../components/LayoutCommponents";
import Modal from "../../../components/Modal"
import styles from "./Seoul.module.scss";
import TColor from '../../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../../styles/TextStyled";
import photo from "../../../images/assets/society/Seoul/photo.jpg"

import s01 from "../../../images/assets/society/Seoul/01.jpg"
import s02 from "../../../images/assets/society/Seoul/02.jpg"
import s03 from "../../../images/assets/society/Seoul/03.jpg"
import s04 from "../../../images/assets/society/Seoul/04.jpg"
import s05 from "../../../images/assets/society/Seoul/main.jpg"

AOS.init({disable: true});

const Seoul = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 최상단 이동
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					서울지회
				</div>
			</div>
			<div className={styles.backGround}>
				<H1 className={styles.head} bold color={TColor.black}>
					서울지회
				</H1>
				<img className={styles.photo} src={photo}/>
				<div className={styles.history}>
					<div className={styles.year}>
						성명
					</div>
					<div className={styles.milestones}>
						전효원 (全 孝苑)
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						소속
					</div>
					<div className={styles.milestones}>
						(사)홍승스님의 사찰음식연구회 서울지회장
						<br/>
						이지자연음식문화원장(대구)/한국자연음식협회장
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						학력
					</div>
					<div className={styles.milestones}>
						경기대학교 대학원 외식산업경영학과 졸업-박사
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						국가자격증
					</div>
					<div className={styles.milestones}>
						중등정교사(가정)/ 영양사/ 사회복지사 1급/ 직업훈련교사 2급
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						민간자격증
					</div>
					<div className={styles.milestones}>
						사찰음식사범, 푸드코디네이터, 꽃차사범, 식이지도사 등 다수<br/>
						수상-향토식문화대전&푸드 코디네이터전 등 대회, 국회의장상, 장관상(여성가족부, 교
						육부, 통일부, 노동부등) 수여 다수
					</div>
				</div>
				<HorizontalRule width={'60%'} height={2}/>
				<div className={styles.history}>
					<div className={styles.year}>
						저서
					</div>
					<div className={styles.milestones}>
						-마음을 담은 사찰음식(공저), 아이좋아 가족밥상(공저)<br/>
						조리기능장실기&필기교재(공저), 맛의 방주 향토편(공저)
					</div>
				</div>
				<div className={styles.history}>
					<div className={styles.year}>
						SNS
					</div>
					<div className={styles.milestones}>
						<a href={'blog.naver.com›etemplecook/'}>블로그 이지사찰음식학교</a>
						<br/>
						<a href={"https://www.instagram.com/etemplecook/"}>인스타그램</a>
					</div>
				</div>
				<div className={styles.history}>
					<div className={styles.year}>
						소개
					</div>
					<div className={styles.milestones}>
						대학에서 식품영양학을 전공하고 교사, 사회복지등 일을 하다가 홍승스님을 만난 후
						사찰음식에 매료되어 사찰음식을 주제로 박사학위를 취득했고, 조계종 문화부 주관
						사찰음식데이터베이스사업에 책임연구원, 대구불교방송(라), 불교저널등 활동을 하면
						서 꾸준히 사찰음식을 알리고 있다.<br/>
						대학에서 학생들을 가르치면서 각종 요리대회에 참여해서 장관상, 우수지도자상을 타
						는 등 후학에도 힘쓰고 있으며<br/>
						현재는 대구 이지(利智)자연음식문화원을 운영하면서 사찰음식, 치유음식, 푸드코디네
						이터등 과정을 개설하여 제자를 배출하고 논문, 서적도 꾸준히 내고 활동중이다.
					</div>
				</div>
			</div>
			<div className={styles.premiumService}>
				<div className={styles.premiumTitle}>
					<h1>지회 사진</h1>
				</div>
				<div className={styles.premiumContents}>
					<HorizontalRule height={3}/>
					<div className={styles.facilityInfo}>
					</div>
					<div className={styles.premiumContentsItem}>
						<img alt={''}
						     src={s01}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.facilityInfo}>
					</div>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={s02}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={s03}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={s04}/>
					</div>
					<HorizontalRule height={3}/>
					<div className={styles.premiumContentsItem}>
						<img alt={''} src={s05}/>
					</div>
				</div>
				<VerticalInterval height={5}/>
			</div>
		</div>
	);
}


export default Seoul;
