import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {HorizontalRule} from "../../components/LayoutCommponents";
import Modal from "../../components/Modal"
import styles from "./Chapter_Introduction.module.scss";
import TColor from '../../styles/TimoStyle';
import {Body1, Body2, H1,H2} from "../../styles/TextStyled";
import { useLocation,Link } from 'react-router-dom';

import ulsan from "../../images/assets/society/Ulsan/main.jpg"
import jeonnam from "../../images/assets/society/Jeonnam/02.png"
import seoul from "../../images/assets/society/Seoul/main.jpg"
import pohang from "../../images/assets/society/Pohang/cheau.png"
import temp from "../../images/assets/wallpage_bg.jpeg"
import busan from "../../images/assets/society/Busan/busanlogo.png"

AOS.init({disable: true});

const Chapter_Introduction = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenResponse, setScreenResponse] = useState(true);
	const tdStyle = { border: "1px solid black" }; // td 스타일 객체
	useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions);
		updateWindowDimensions();
		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);
	
	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
		width > 722 ?
			setScreenResponse(true)
			:
			setScreenResponse(false);
	}
	return (
		<div style={{width: '100%'}}>
			<div className={styles.wallPage}>
				<div className={styles.titleText}>
					<br/>
					지회 소개
				</div>
			</div>
			<div>
				<div className={styles.board}>
					<Link style={{textDecoration: 'none', color: 'inherit'}} to="/templefoodsociety/chapterintroduction/Seoul">
						<div className={styles.group}>
							<img className={styles.image} src={seoul}/>
							<Body2 className={styles.title} bold color={TColor.black}>
								서울 & 대구 지회
							</Body2>
						</div>
					</Link>
					<Link style={{textDecoration: 'none', color: 'inherit'}} to="/templefoodsociety/chapterintroduction/Busan">
						<div className={styles.group}>
							<img className={styles.image} src={busan}/>
							<Body2 className={styles.title} bold color={TColor.black}>
								부산 - 소연힐링푸드연구소
							</Body2>
						</div>
					</Link>
					{/*<div className={styles.group}>*/}
					{/*	<img className={styles.image} src={ulsan}/>*/}
					{/*	<Body2 className={styles.title} bold color={TColor.black}>*/}
					{/*		대구*/}
					{/*	</Body2>*/}
					{/*</div>*/}
				</div>
				<div className={styles.board}>
					<Link style={{textDecoration: 'none', color: 'inherit'}} to="/templefoodsociety/chapterintroduction/pohang">
						<div className={styles.group}>
							<img className={styles.image} src={pohang}/>
							<Body2 className={styles.title} bold color={TColor.black}>
								포항 - 자연음식 연구소
							</Body2>
						</div>
					</Link>
					<Link style={{textDecoration: 'none', color: 'inherit'}} to="/templefoodsociety/chapterintroduction/ulsan">
						<div className={styles.group}>
							<img className={styles.image} src={ulsan}/>
							<Body2 className={styles.title} bold color={TColor.black}>
								울산 - 김경서 사찰음식연구원
							</Body2>
						</div>
					</Link>
					<Link style={{textDecoration: 'none', color: 'inherit'}} to="/templefoodsociety/chapterintroduction/jeonnam">
						<div className={styles.group}>
							<img className={styles.image} src={jeonnam}/>
							<Body2 className={styles.title} bold color={TColor.black}>
								전남 - 윤문희 자연요리 문화원
							</Body2>
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}


export default Chapter_Introduction;
