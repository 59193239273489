
import styled from "styled-components";
import TColor from "./TimoStyle";

/*
type TextProps = {
    bold?: boolean,
    underline?: boolean,
    color?: string,

    // align
    center?: boolean,
    right?: boolean,
}
*/

const buildTextStyle = (fontSize) => styled.div`
    font-size: ${fontSize}px;
    font-weight: ${(props) => props.bold ? 'bold' : 'normal'};
    text-decoration-line: ${(props) => props.underline ? 'underline' : 'none'};
    text-align: ${(props) => props.center ? 'center' : props.right ? 'right' : 'left'};
    line-height: ${fontSize * 1.5}px;
    color: ${(props) => props.color || TColor.black};
`;

export const H1 = buildTextStyle(32);
export const H2 = buildTextStyle(24);

export const Body1 = buildTextStyle(20);
export const Body2 = buildTextStyle(17);
export const Body3 = buildTextStyle(15);
export const Caption = buildTextStyle(13);




